import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SelectButtonItem } from '../../../types/chatbot/scenario.types';

export type SelectButtonState = {
  selectButtonItems: SelectButtonItem[];
  selectedText: string | undefined;
};

const initialState: SelectButtonState = {
  selectButtonItems: [],
  selectedText: undefined,
};

export const selectButtonSlice = createSlice({
  name: 'selectButton',
  initialState,
  reducers: {
    updateSelectButtonItems: (state, action: PayloadAction<SelectButtonItem[]>) => {
      state.selectButtonItems = action.payload;
    },
    updateSelectedText: (state, action: PayloadAction<string | undefined>) => {
      state.selectedText = action.payload;
    },
    resetSelectButton: () => initialState,
  },
});

export const { updateSelectButtonItems, updateSelectedText, resetSelectButton } = selectButtonSlice.actions;

export default selectButtonSlice.reducer;
