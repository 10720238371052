// NOTE: 一旦 avatar-sample のコードをそのまま持ってきている
// TODO: hook 化する

export const createMediaStream = ({
  audioTrack = null,
  videoTrack = null,
  videoContentHint,
}: {
  audioTrack: MediaStreamTrack | null;
  videoTrack: MediaStreamTrack | null;
  // TODO: ContentHint 型を作る
  videoContentHint: string;
}) => {
  const stream = new MediaStream();

  if (audioTrack instanceof MediaStreamTrack) {
    stream.addTrack(audioTrack);
  }

  if (videoTrack instanceof MediaStreamTrack) {
    // FIXME: esLintで型のエラーが出る
    // eslint-disable-next-line no-param-reassign
    videoTrack.contentHint = videoContentHint;
    stream.addTrack(videoTrack);
  }

  return stream;
};

/* UserMedia  */
// UserMedia は videoTrack と audioTrack をそれぞれ別デバイスから取得するため
// video/audio それぞれを直接取得する util を提供している

export const getUserAudioTrack = async (deviceId: string): Promise<MediaStreamTrack> => {
  const constraints = deviceId === '' ? true : { deviceId: { exact: deviceId } };
  const stream = await navigator.mediaDevices.getUserMedia({ audio: constraints });

  return stream.getAudioTracks()[0];
};

export const getUserVideoTrack = async (deviceId: string): Promise<MediaStreamTrack> => {
  const constraints =
    deviceId === ''
      ? true
      : {
          deviceId: { exact: deviceId },
          // TODO: 定数を別モジュールに切り出す in issue #307
          width: { ideal: 1920, max: 1920 },
          height: { ideal: 1080, max: 1080 },
          frameRate: 60,
        };
  const stream = await navigator.mediaDevices.getUserMedia({ video: constraints });

  return stream.getVideoTracks()[0];
};

/* DisplayMedia  */
// DisplayMedia は1つの Stream から video と audio をまとめて取得する

export const getDisplayMediaTracks = async (
  constraints: MediaStreamConstraints,
): Promise<{
  videoTrack: MediaStreamTrack | null;
  audioTrack: MediaStreamTrack | null;
}> =>
  navigator.mediaDevices.getDisplayMedia(constraints).then((stream) => {
    const videoTrack = stream.getVideoTracks()[0];
    const audioTrack = stream.getAudioTracks()[0];

    return {
      videoTrack: videoTrack || null,
      audioTrack: audioTrack || null,
    };
  });
