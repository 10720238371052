import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// FIXME: リロードをがfireStore経由になったら削除
// オペレーターの指示と内容を合わせているが、最適化したい
export type ForceReloadState = {
  type?: 'forceReload';
};

const initialState: ForceReloadState = {};

export const forceReloadSlice = createSlice({
  name: 'forceReload',
  initialState,
  reducers: {
    resetForceReload: () => initialState,
    updateForceReload: (state, action: PayloadAction<ForceReloadState>) => {
      state.type = action.payload.type;
    },
  },
});

export const { resetForceReload, updateForceReload } = forceReloadSlice.actions;

export default forceReloadSlice.reducer;
