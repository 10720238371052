import {
  getAuth,
  inMemoryPersistence,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInAnonymously,
  signOut,
  User,
  browserSessionPersistence,
  browserLocalPersistence,
  Auth,
  getIdTokenResult,
} from 'firebase/auth';
import { captureException } from '@sentry/react';
import { FirebaseError } from 'firebase/app';
import { formatISOInJST } from '@avita-co-jp/frontend-utils';
import { firebaseApp } from './app';
import { SignInArgs, IdentityState } from './auth.types';
import { authErrorCodes } from '../errors/ErrorLogin';

/**
 * firebaseAuthの取得
 */
export function generateFirebaseAuth(): Auth {
  return getAuth(firebaseApp);
}

/**
 * カスタムトークンでサインイン
 * @param {string} customToken
 */
export async function signInByCustomToken(customToken: string): Promise<IdentityState> {
  // 認証状態の永続性の変更
  // https://firebase.google.com/docs/auth/web/auth-state-persistence?hl=ja#modifying_the_auth_state_persistence
  const firebaseAuth = generateFirebaseAuth();
  try {
    await setPersistence(firebaseAuth, inMemoryPersistence);
    const { user } = await signInWithCustomToken(firebaseAuth, customToken);
    const idTokenResult = await getIdTokenResult(user);
    const expirationTime = new Date(idTokenResult.expirationTime).getTime() + 1000 * 60 * 60 * 9; // JSTに変換
    return {
      firebaseToken: idTokenResult.token,
      firebaseUuid: user.uid,
      firebaseRefreshToken: user.refreshToken,
      firebaseEmail: user.email,
      firebaseTokenExpireAt: new Date(expirationTime).toISOString(),
      firebaseCreationTime: user.metadata.creationTime,
    };
  } catch (error) {
    const signInFirebaseError = error as Error;
    captureException(signInFirebaseError);
    // 認証エラーは400に丸める
    throw new Error('400');
  }
}

/**
 * firebaseToken(IDToken)の更新
 * @param {User} userResponse
 */
async function generateIdentity(userResponse: User): Promise<IdentityState> {
  // TODO: トークンの自動更新を検知するようにしたら更新を強制しないようにする
  const idTokenResult = await getIdTokenResult(userResponse, true);
  return {
    firebaseToken: idTokenResult.token,
    firebaseUuid: userResponse.uid,
    firebaseRefreshToken: userResponse.refreshToken,
    firebaseEmail: userResponse.email,
    firebaseTokenExpireAt: formatISOInJST(new Date(idTokenResult.expirationTime)),
    firebaseCreationTime: userResponse.metadata.creationTime,
  };
}

/**
 * Firebaseにサインイン（パスワードとEメール・セッションの間はログイン状態を維持）
 * @param {SignInArgs}
 */
export async function signInFirebaseWithEmailAndPassword({
  email,
  password,
  isLocalPersistence = false,
}: SignInArgs): Promise<IdentityState> {
  const firebaseAuth = generateFirebaseAuth();
  await setPersistence(firebaseAuth, isLocalPersistence ? browserLocalPersistence : browserSessionPersistence);
  return signInWithEmailAndPassword(firebaseAuth, email, password)
    .then(async (result) => {
      const identity = await generateIdentity(result.user);
      return identity;
    })
    .catch((e: FirebaseError) => {
      const signInFirebaseError = e as Error;
      captureException(signInFirebaseError);
      if (authErrorCodes.includes(e.code)) {
        // NOTE: クライアント側のエラー（401）
        throw new Error('401');
      }
      // NOTE: クライアント側のエラー以外(500)
      throw new Error('500');
    });
}

/**
 * Firebaseにサインイン（匿名・セッションの間はログイン状態を維持）
 */
export async function signInFirebaseAnonymouslySession(): Promise<IdentityState> {
  const firebaseAuth = generateFirebaseAuth();
  await setPersistence(firebaseAuth, browserSessionPersistence);
  return signInAnonymously(firebaseAuth)
    .then(async (result) => {
      const identity = await generateIdentity(result.user);
      return identity;
    })
    .catch((e) => {
      const signInFirebaseError = e as Error;
      captureException(signInFirebaseError);
      throw new Error('匿名ログインに失敗しました');
    });
}

/**
 * Firebaseにサインイン（匿名・ログイン状態を維持しない）
 */
export async function signInFirebaseAnonymously(): Promise<IdentityState> {
  const firebaseAuth = generateFirebaseAuth();
  await setPersistence(firebaseAuth, inMemoryPersistence);
  return signInAnonymously(firebaseAuth)
    .then(async (result) => {
      const idTokenResult = await getIdTokenResult(result.user);
      const expirationTime = new Date(idTokenResult.expirationTime).getTime() + 1000 * 60 * 60 * 9; // JSTに変換
      return {
        firebaseToken: idTokenResult.token,
        firebaseUuid: result.user.uid,
        firebaseRefreshToken: result.user.refreshToken,
        firebaseEmail: result.user.email,
        firebaseTokenExpireAt: new Date(expirationTime).toISOString(),
        firebaseCreationTime: result.user.metadata.creationTime,
      };
    })
    .catch((e) => {
      const signInFirebaseError = e as Error;
      captureException(signInFirebaseError);
      // 認証エラーは400に丸める
      throw new Error('400');
    });
}

/**
 * Firebaseをサインアウト
 */
export function signOutFirebase(): Promise<void> {
  return new Promise((resolve, reject) => {
    const firebaseAuth = generateFirebaseAuth();
    signOut(firebaseAuth)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}

/**
 * リフレッシュ処理
 */
export function refreshFirebase(): Promise<IdentityState> {
  const firebaseAuth = generateFirebaseAuth();
  if (!firebaseAuth.currentUser) throw new Error('ログインしていません');
  return generateIdentity(firebaseAuth.currentUser);
}
