import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { getUserVideoTrack } from '../../LocalMediaStreamProvider.utils';
import { useReleaseTrack } from '../useReleaseTrack/useReleaseTrack.hook';
import { useSendVideoDeviceResolution } from '../useSendVideoDeviceResolution';
import { UseLocalVideoTrackProps } from './useLocalVideoTrack.types';
import { useNotifyCameraSelectionError } from './useNotifyCameraSelectionError';
import { VIDEO_DEVICE_NONE_ID } from '../../../../constants/Device';

export const useLocalVideoTrack = ({
  selectedVideoDeviceId,
  handleError,
  videoDeviceList,
}: UseLocalVideoTrackProps) => {
  // NOTE: videoTrackの初期状態をundefinedにする
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack | 'none' | undefined>(undefined);
  const { releaseTrack } = useReleaseTrack();
  useSendVideoDeviceResolution({
    videoTrack,
    videoDeviceList: videoDeviceList?.currentVideoInDevices,
  });

  const { handleNotifyCameraSelectionError } = useNotifyCameraSelectionError();

  useEffect(() => {
    if (selectedVideoDeviceId === undefined) return; // NOTE: 初回レンダリング時は何もしない

    // NOTE: getUserMediaをする前に現在のトラックをストップする
    if (videoTrack && videoTrack !== 'none') releaseTrack(videoTrack);

    if (selectedVideoDeviceId === null) {
      setVideoTrack('none');
    } else if (selectedVideoDeviceId === VIDEO_DEVICE_NONE_ID) {
      setVideoTrack('none');
    } else {
      (async () => {
        const track = await getUserVideoTrack(selectedVideoDeviceId);
        setVideoTrack(track);
      })().catch((e) => {
        setVideoTrack('none');
        if (handleError) {
          handleError('カメラに接続できません。別のアプリケーションで使用されている可能性があります。');
        }
        if (e instanceof DOMException && e.name === 'NotReadableError') {
          // NOTE: ADDにエラーを通知する
          handleNotifyCameraSelectionError(
            'カスタマーのカメラの選択に失敗しました。別のアプリケーションで使用されている可能性があります。',
          ).catch((error) => {
            captureException(error);
          });
        } else {
          // NOTE: ADDにエラーを通知する(NotAllowedError,NotFoundError,OverconstrainedError,SecurityError,TypeError)
          handleNotifyCameraSelectionError(
            '選択可能なカスタマーのカメラデバイスが見つかりません。カスタマーのカメラデバイスを確認してください。',
          ).catch((error) => {
            captureException(error);
          });
        }
      });
    }
    // NOTE: videoTrackには依存させない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoDeviceId, releaseTrack, handleError, handleNotifyCameraSelectionError]);

  return { videoTrack };
};
