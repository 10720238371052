import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { UseOperatorVideoTrack, UseOperatorVideoTrackProps } from './useOperatorVideoTrack.types';
import { getADTCameraTrack } from './useOperatorVideoTrack.utils';

export const useOperatorVideoTrack = ({ adtCameraDeviceId }: UseOperatorVideoTrackProps): UseOperatorVideoTrack => {
  const [operatorVideoTrack, setOperatorVideoTrack] = useState<MediaStreamTrack | null>(null);
  const [isADTCameraVertical, setIsADTCameraVertical] = useState<boolean>(false);

  const updateIsADTCameraVertical = useCallback((status: boolean) => {
    setIsADTCameraVertical(status);
  }, []);

  useEffect(() => {
    // トラック取得前に既存のトラックを停止する
    setOperatorVideoTrack((prevTrack) => {
      prevTrack?.stop();

      return prevTrack;
    });

    if (adtCameraDeviceId === null || adtCameraDeviceId === undefined) return;

    getADTCameraTrack({
      deviceId: adtCameraDeviceId,
    })
      .then((track) => {
        setOperatorVideoTrack(track);
      })
      .catch((e) => {
        captureException(e);
        setOperatorVideoTrack(null);
      });
  }, [adtCameraDeviceId, isADTCameraVertical]);

  return {
    operatorVideoTrack,
    updateIsADTCameraVertical,
  };
};
