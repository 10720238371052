import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  signInFirebaseWithEmailAndPassword,
  signInFirebaseAnonymously,
  signOutFirebase,
  refreshFirebase,
  signInFirebaseAnonymouslySession,
  signInByCustomToken,
} from '../../../firebase/auth';
import { SignInArgs, IdentityState } from '../../../firebase/auth.types';

// Firebaseにサインイン（パスワードとEメール・セッションの間はログイン状態を維持）
export const signInWithEmailAndPasswordThunk = createAsyncThunk<IdentityState, SignInArgs>(
  'authFirebase/signIn',
  (signInArgs) => signInFirebaseWithEmailAndPassword({ ...signInArgs }),
);

// Firebaseにサインイン（匿名・セッションの間はログイン状態を維持）
export const signInAnonymouslySessionThunk = createAsyncThunk('authFirebase/signInAnonymouslySession', () =>
  signInFirebaseAnonymouslySession(),
);

// Firebaseにサインイン（匿名・ログイン状態を維持しない）
export const signInAnonymouslyThunk = createAsyncThunk('authFirebase/signInAnonymously', () =>
  signInFirebaseAnonymously(),
);

// Firebaseにサインイン（カスタムトークン・ログイン状態を維持しない）
export const signInByCustomTokenThunk = createAsyncThunk<IdentityState, string>(
  'authFirebase/signInByCustomToken',
  (customToken) => signInByCustomToken(customToken),
);

// Firebaseをサインアウト
export const signOutFirebaseThunk = createAsyncThunk('authFirebase/signOut', async () => signOutFirebase());

// リフレッシュ処理
export const refreshFirebaseThunk = createAsyncThunk('authFirebase/refresh', () => refreshFirebase());

export type AuthFirebaseState = {
  loading: boolean;
  error: string[];
  identity?: IdentityState;
  statusCode?: number;
};

const initialState: AuthFirebaseState = {
  loading: false,
  error: [],
};

export const authFirebaseSlice = createSlice({
  name: 'authFirebase',
  initialState,
  reducers: {
    signOutAuthFirebase: () => initialState,
    updateAuthFirebaseIdentity: (state, action: PayloadAction<IdentityState>) => ({
      ...state,
      identity: {
        ...state.identity,
        ...action.payload,
      },
    }),
    throwErrorAuthIdentity: (state, action: PayloadAction<{ errorCode: number }>) => ({
      ...state,
      identity: undefined,
      loading: false,
      error: ['Auth error'],
      statusCode: action.payload.errorCode,
    }),
    resetAuthErrorStatus: (state) => {
      state.statusCode = undefined;
      state.error = [];
    },
  },
  // eslint-disable-next-line max-lines-per-function
  extraReducers: (builder) => {
    // TODO: エラーメッセージをerrorに入れる
    // authFirebase/signIn
    builder.addCase(signInWithEmailAndPasswordThunk.fulfilled, (_, action: PayloadAction<IdentityState>) => ({
      loading: false,
      error: [],
      identity: action.payload,
    }));
    builder.addCase(signInWithEmailAndPasswordThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signInWithEmailAndPasswordThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = ['Auth error'];
      state.statusCode = action.error.message === '401' ? 401 : 500;
    });

    // authFirebase/signInAnonymouslySession
    builder.addCase(signInAnonymouslySessionThunk.fulfilled, (_, action: PayloadAction<IdentityState>) => ({
      loading: false,
      error: [],
      identity: action.payload,
    }));
    builder.addCase(signInAnonymouslySessionThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signInAnonymouslySessionThunk.rejected, (state) => {
      state.loading = false;
      state.error = ['Auth error'];
    });

    // authFirebase/signInAnonymously
    builder.addCase(signInAnonymouslyThunk.fulfilled, (_, action: PayloadAction<IdentityState>) => ({
      loading: false,
      error: [],
      identity: action.payload,
    }));
    builder.addCase(signInAnonymouslyThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signInAnonymouslyThunk.rejected, (state) => {
      state.loading = false;
      state.error = ['Auth error'];
      // 認証エラーはステータスコードを400にする
      state.statusCode = 400;
    });

    // authFirebase/signInByCustomToken
    builder.addCase(signInByCustomTokenThunk.fulfilled, (_, action: PayloadAction<IdentityState>) => ({
      loading: false,
      error: [],
      identity: action.payload,
    }));
    builder.addCase(signInByCustomTokenThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signInByCustomTokenThunk.rejected, (state) => {
      state.loading = false;
      state.error = ['Auth error'];
      // 認証エラーはステータスコードを400にする
      state.statusCode = 400;
    });

    // authFirebase/signOut
    builder.addCase(signOutFirebaseThunk.fulfilled, () => ({
      loading: false,
      error: [],
      identity: undefined,
    }));
    builder.addCase(signOutFirebaseThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signOutFirebaseThunk.rejected, (state) => {
      state.loading = false;
      state.error = ['Auth error'];
    });

    // authFirebase/refresh
    builder.addCase(refreshFirebaseThunk.fulfilled, (state, action: PayloadAction<IdentityState>) => ({
      loading: false,
      error: [],
      identity: {
        ...state.identity,
        ...action.payload,
      },
    }));
    builder.addCase(refreshFirebaseThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshFirebaseThunk.rejected, (state) => {
      state.loading = false;
      state.error = ['Auth error'];
    });
  },
});

export const { signOutAuthFirebase, updateAuthFirebaseIdentity, resetAuthErrorStatus, throwErrorAuthIdentity } =
  authFirebaseSlice.actions;
export default authFirebaseSlice.reducer;
