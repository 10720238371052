import { GetADTCameraTrackArgs } from './useOperatorVideoTrack.types';

// ADTカメラが対応している16:9の解像度リスト
const ADT_CAMERA_HORIZONTAL_RESOLUTION_LIST = [
  [320, 180],
  [480, 270],
  [640, 360],
  [960, 540],
  [1280, 720],
  [1920, 1080],
  [2560, 1440],
  [3840, 2160],
];

// 16:9の横の長さのリスト
// 解像度のうち大きい方の長さのリストになっている
const ADT_CAMERA_HORIZONTAL_WIDTH_LIST = ADT_CAMERA_HORIZONTAL_RESOLUTION_LIST.map(([width]) => width);

/**
 * 大きい方の長さを受け取って解像度の組み合わせを返す
 */
function determineADTCameraResolution(largerLength: number): number[] {
  for (let i = 0; i < ADT_CAMERA_HORIZONTAL_WIDTH_LIST.length; i += 1) {
    // 大きい方の長さがリストの長さ以下の場合は対象の解像度を返す
    if (largerLength <= ADT_CAMERA_HORIZONTAL_WIDTH_LIST[i]) {
      return ADT_CAMERA_HORIZONTAL_RESOLUTION_LIST[i];
    }
  }
  // ここに来た場合は大きい方の長さが3840以上
  // ADTカメラが対応していないので最大の解像度を返す
  return ADT_CAMERA_HORIZONTAL_RESOLUTION_LIST[ADT_CAMERA_HORIZONTAL_RESOLUTION_LIST.length - 1];
}

export function getADTCameraTrack({ deviceId }: GetADTCameraTrackArgs): Promise<MediaStreamTrack> {
  if (deviceId === '') throw new Error('deviceId is empty');

  const { width, height } = window.screen;
  const largerLength = Math.max(width, height);
  const isVertical = width < height;
  const resolution = determineADTCameraResolution(largerLength);
  // 縦長の場合は解像度を入れ替える
  const idealWidth = isVertical ? resolution[1] : resolution[0];
  const idealHeight = isVertical ? resolution[0] : resolution[1];

  const constraints = {
    deviceId: { exact: deviceId },
    width: { ideal: idealWidth },
    height: { ideal: idealHeight },
    frameRate: 60,
  };

  return navigator.mediaDevices.getUserMedia({ video: constraints }).then((stream) => stream.getVideoTracks()[0]);
}
