import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScenarioMotionType } from '../../../types';

export type ServicingState = {
  isOpenServicingMenu: boolean;
  visibleInvocationMovie: boolean;
  visibleFaceDetectionMovie: boolean;
  visibleTransitionInMovie: boolean;
  visibleTransitionOutMovie: boolean;
  avatarMotion: ScenarioMotionType;
};

const initialState: ServicingState = {
  isOpenServicingMenu: false,
  visibleInvocationMovie: false,
  visibleFaceDetectionMovie: false,
  visibleTransitionInMovie: false,
  visibleTransitionOutMovie: false,
  avatarMotion: ScenarioMotionType.Wait,
};

export const servicingSlice = createSlice({
  name: 'servicing',
  initialState,
  reducers: {
    openServicingMenu: (state) => {
      state.isOpenServicingMenu = true;
    },
    closeServicingMenu: (state) => {
      state.isOpenServicingMenu = false;
    },
    startInvocationMovie: (state) => {
      state.visibleInvocationMovie = true;
    },
    stopInvocationMovie: (state) => {
      state.visibleInvocationMovie = false;
    },
    startFaceDetectionMovie: (state) => {
      state.visibleFaceDetectionMovie = true;
    },
    stopFaceDetectionMovie: (state) => {
      state.visibleFaceDetectionMovie = false;
    },
    startTransitionInMovie: (state) => {
      state.visibleTransitionInMovie = true;
    },
    stopTransitionInMovie: (state) => {
      state.visibleTransitionInMovie = false;
    },
    startTransitionOutMovie: (state) => {
      state.visibleTransitionOutMovie = true;
    },
    stopTransitionOutMovie: (state) => {
      state.visibleTransitionOutMovie = false;
    },
    updateAvatarMotion: (state, action: PayloadAction<ScenarioMotionType>) => {
      state.avatarMotion = action.payload;
    },
  },
});

export const {
  openServicingMenu,
  closeServicingMenu,
  startInvocationMovie,
  stopInvocationMovie,
  startFaceDetectionMovie,
  stopFaceDetectionMovie,
  startTransitionInMovie,
  stopTransitionInMovie,
  startTransitionOutMovie,
  stopTransitionOutMovie,
  updateAvatarMotion,
} = servicingSlice.actions;

export default servicingSlice.reducer;
