import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerMatchingStatus } from '../../../hooks/useRoomConductor/useRoomConductor.types';
import { OnlineCustomerStatus } from './onlineCustomerStatus.types';
import { CustomerRoomStatus } from '../../../hooks/usePresenceCheck/usePresenceCheck.types';

export const onlineCustomerStatusSlice = createSlice({
  name: 'onlineCustomerStatus',
  initialState: {
    matchingStatus: CustomerMatchingStatus.Waiting,
    roomStatus: CustomerRoomStatus.BeforeEntering,
    numberOfWaitingCustomerBeforeMe: 0,
    isNumberOfWaitingCustomerAboveThreshold: false,
    isAssigned: false,
  },
  reducers: {
    updateOnlineCustomerStatus: (_, action: PayloadAction<OnlineCustomerStatus>) => action.payload,
    setMatchingStatus: (state, action: PayloadAction<CustomerMatchingStatus>) => {
      state.matchingStatus = action.payload;
    },
  },
});

export const { updateOnlineCustomerStatus, setMatchingStatus } = onlineCustomerStatusSlice.actions;

export default onlineCustomerStatusSlice.reducer;
