import styles from './ErrorContent.module.scss';
import { ErrorContentProps } from './ErrorContent.types';

export const ErrorContent = ({ status, children }: ErrorContentProps): JSX.Element => (
  <div className={styles.container}>
    <div data-chromatic="ignore" className={styles.imageError} />
    <span className={styles.status}>{status}</span>
    <div className={styles.detail}>{children}</div>
  </div>
);
