import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RoomSessionHistoryItem, RoomSessionText } from './firestoreChat.types';

/**
 * 選択式対話のアダプターを生成
 */
export const RoomSessionHistoryListAdapter = createEntityAdapter<RoomSessionHistoryItem>({
  selectId: (roomSessionHistoryItem) => roomSessionHistoryItem.historyID,
});

/**
 * テキストチャットのアダプターを生成
 */
export const RoomSessionChatHistoryListAdapter = createEntityAdapter<RoomSessionText>({
  selectId: (roomSessionChatHistoryItem) => roomSessionChatHistoryItem.historyID,
});

const initialState: {
  textChat: EntityState<RoomSessionText>;
  selectiveTalk: EntityState<RoomSessionHistoryItem>;
  currentChatHistoryID?: string;
} = {
  textChat: RoomSessionChatHistoryListAdapter.getInitialState(),
  selectiveTalk: RoomSessionHistoryListAdapter.getInitialState(),
  currentChatHistoryID: undefined,
};

export const firestoreChatSlice = createSlice({
  name: 'firestoreChat',
  initialState,
  reducers: {
    resetChatHistory: () => initialState,
    resetFirestoreRoomSessionHistory(state) {
      RoomSessionHistoryListAdapter.removeAll(state.selectiveTalk);
    },
    updateFirestoreRoomSessionHistory(state, action: PayloadAction<RoomSessionHistoryItem[]>) {
      RoomSessionHistoryListAdapter.setAll(state.selectiveTalk, action.payload);
    },
    upsertOneFirestoreRoomSessionHistory(state, action: PayloadAction<RoomSessionHistoryItem>) {
      RoomSessionHistoryListAdapter.upsertOne(state.selectiveTalk, action.payload);
    },
    resetFirestoreRoomSessionChatHistory(state) {
      RoomSessionChatHistoryListAdapter.removeAll(state.textChat);
    },
    updateFirestoreRoomSessionChatHistory(state, action: PayloadAction<RoomSessionText[]>) {
      RoomSessionChatHistoryListAdapter.setAll(state.textChat, action.payload);
    },
    upsertManyFirestoreRoomSessionChatHistory(state, action: PayloadAction<RoomSessionText[]>) {
      RoomSessionChatHistoryListAdapter.upsertMany(state.textChat, action.payload);
    },
    updateCurrentChatHistoryID(state, action: PayloadAction<string | undefined>) {
      state.currentChatHistoryID = action.payload;
    },
  },
});

export const {
  resetChatHistory,
  resetFirestoreRoomSessionHistory,
  updateFirestoreRoomSessionHistory,
  upsertOneFirestoreRoomSessionHistory,
  resetFirestoreRoomSessionChatHistory,
  updateFirestoreRoomSessionChatHistory,
  upsertManyFirestoreRoomSessionChatHistory,
  updateCurrentChatHistoryID,
} = firestoreChatSlice.actions;

export default firestoreChatSlice.reducer;
