import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { refreshFirebase } from '../../firebase/auth';
import { updateAuthFirebaseIdentity } from '../../store/features/authFirebase';

export function useRefreshIdentityFirebase() {
  const dispatch = useDispatch();
  const refreshIdentityFirebase = useCallback(async () => {
    const identity = await refreshFirebase();
    dispatch(updateAuthFirebaseIdentity(identity));
    return identity;
  }, [dispatch]);
  return { refreshIdentityFirebase };
}
