/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JWT: string;
  URL: string;
};

/** ADT背景 */
export type AdtBackground = {
  __typename?: 'ADTBackground';
  /**  ラベル  */
  label: Scalars['String'];
  /**  URL  */
  url: Scalars['URL'];
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** 選択式対話の履歴（選択肢）を作成 */
export type AddHistoryWithAnswerInput = {
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームID TODO:uuidに切り替えたら削除する  */
  roomId?: InputMaybe<Scalars['Int']>;
  /**  ルームuuid  */
  roomUuid: Scalars['ID'];
  /**  ユーザータイプ  */
  selectedBy: UserType;
  /**  セッションID  */
  sessionId: Scalars['String'];
  templateHistoryId: Scalars['String'];
  /**  回答のテキスト  */
  texts: Array<Scalars['String']>;
};

/** 選択式対話の履歴（テンプレート）を作成 */
export type AddHistoryWithTemplateInput = {
  /**  テンプレートに紐づく選択肢のテキスト  */
  choiceTexts: Array<Scalars['String']>;
  /**  テンプレートの選択肢のタイプ  */
  choiceType: ChoiceType;
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームID TODO:uuidに切り替えたら削除する  */
  roomId?: InputMaybe<Scalars['Int']>;
  /**  ルームuuid  */
  roomUuid: Scalars['ID'];
  /**  セッションID  */
  sessionId: Scalars['String'];
  /**  テンプレートID TODO:uuidに切り替えたら削除する  */
  templateId?: InputMaybe<Scalars['Int']>;
  /**  テンプレートuuid  */
  templateUuid?: InputMaybe<Scalars['ID']>;
  /**  テンプレートのタイトル  */
  title: Scalars['String'];
};

/** Azure AI Service の Token 情報 */
export type AzureAiServiceToken = {
  __typename?: 'AzureAIServiceToken';
  /**  有効期限  */
  expireAt: Scalars['DateTime'];
  /**  アクセストークン  */
  token: Scalars['JWT'];
};

export enum ChoiceType {
  /**  選択肢の中から複数選択  */
  Multiple = 'MULTIPLE',
  /**  選択肢の中から1つ選択  */
  Single = 'SINGLE'
}

/** 呼び出し開始 */
export type CreateInvocationInput = {
  /**  ルームキー  */
  roomKey: Scalars['String'];
  /**  source  */
  source: Source;
};

export enum CustomRule {
  /**  空文字, 空配列禁止  */
  NotEmpty = 'NotEmpty',
  /**  パストラバーサル禁止  */
  NotPathTraversal = 'NotPathTraversal'
}

/** カスタマー */
export type Customer = {
  __typename?: 'Customer';
  /**  作成日時  */
  createdAt: Scalars['DateTime'];
  /**  カスタマーのカメラ設定(カメラは最初は設定されていない場合もある)  */
  customerCameraSetting?: Maybe<CustomerCameraSetting>;
  /**  customerドキュメントのlistenPath  */
  customerDocumentListenPath?: Maybe<Scalars['String']>;
  /**  カスタマー表示名  */
  displayName: Scalars['String'];
  /**  エントランス画像  */
  entranceImage?: Maybe<Image>;
  /**  エントランス動画  */
  entranceMovie?: Maybe<Movie>;
  /**  カスタマーのfirebase uuid  */
  firebaseUuid: Scalars['String'];
  /**
   *  customerドキュメントのlistenPath
   * @deprecated customerDocumentListenPath フィールドに移行
   */
  firestoreCustomerListenPath?: Maybe<Scalars['String']>;
  /**
   *  roomドキュメントのlistenPath
   * @deprecated roomDocumentListenPath フィールドに移行
   */
  firestoreRoomListenPath: Scalars['String'];
  /**  LP案件の見出し  */
  header?: Maybe<Scalars['String']>;
  /**
   *  カスタマーID TODO:uuidに切り替えたら削除する
   * @deprecated change to firebaseUuid
   */
  id: Scalars['Int'];
  /**  LP案件の紹介文  */
  introductionMessage?: Maybe<Scalars['String']>;
  /**  カスタマー名  */
  name: Scalars['String'];
  /**  プロジェクト  */
  project?: Maybe<Project>;
  /**
   *  プロジェクトID TODO:uuidに切り替えたら削除する
   * @deprecated change to projectUuid
   */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  customerの realtime database listenPath  */
  realtimeDBListenPath?: Maybe<Scalars['String']>;
  /**  roomドキュメントのlistenPath  */
  roomDocumentListenPath: Scalars['String'];
  /**  更新日時  */
  updatedAt: Scalars['DateTime'];
};

export type CustomerCameraDeviceInput = {
  deviceId: Scalars['String'];
  label: Scalars['String'];
};

/**  カスタマーのカメラ設定  */
export type CustomerCameraSetting = {
  __typename?: 'CustomerCameraSetting';
  mainCameraDeviceId?: Maybe<Scalars['String']>;
  originHeight: Scalars['Int'];
  originWidth: Scalars['Int'];
};

/** ADT のカスタマーレンダリング機能が有効になっている場合に使用する動画の種類 */
export enum CustomerRenderingMovieCategory {
  /**  ADT背景動画  */
  AdtBackground = 'ADT_BACKGROUND',
  /**  待機動画  */
  Offline = 'OFFLINE'
}

/** 呼び出し終了 */
export type DeleteInvocationInput = {
  /**  ルームキー  */
  roomKey: Scalars['String'];
};

/** room の document の roomKey 削除 */
export type DeleteRoomKeyFromRoomDocumentInput = {
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームID TODO:uuidに切り替えたら削除する  */
  roomId?: InputMaybe<Scalars['Int']>;
  /**  ルームuuid  */
  roomUuid: Scalars['ID'];
};

/** firestore の ReloadRequired field 更新 */
export type DisableRoomReloadRequiredInput = {
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームID TODO:uuidに切り替えたら削除する  */
  roomId?: InputMaybe<Scalars['Int']>;
  /**  ルームuuid  */
  roomUuid: Scalars['ID'];
};

/** グループ */
export type Group = {
  __typename?: 'Group';
  /**  作成日時  */
  createdAt: Scalars['DateTime'];
  /**
   *  グループID TODO:uuidに切り替えたら削除する
   * @deprecated change to uuid
   */
  id: Scalars['Int'];
  /**  グループ名  */
  name: Scalars['String'];
  /**  更新日時  */
  updatedAt: Scalars['DateTime'];
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** 画像 */
export type Image = {
  __typename?: 'Image';
  /**  カテゴリー  */
  category: ImageCategory;
  /**  ラベル  */
  label: Scalars['String'];
  /**  画像のURL  */
  url: Scalars['URL'];
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** 画像カテゴリー */
export enum ImageCategory {
  /**  ADT背景画像  */
  AdtBackground = 'ADT_BACKGROUND',
  /**  入室時のエントランスで表示される画像  */
  Entrance = 'ENTRANCE',
  /**  アバター画像  */
  FrontAvatar = 'FRONT_AVATAR'
}

/** 案件LP */
export type LpCredential = {
  __typename?: 'LPCredential';
  /**  apiKey  */
  apiKey: Scalars['String'];
  /**  案件LP表示名  */
  displayName: Scalars['String'];
  /**  見出し  */
  header: Scalars['String'];
  /**
   *  案件LP ID TODO:uuidに切り替えたら削除する
   * @deprecated change to uuid
   */
  id: Scalars['Int'];
  /**  案件用LP文言  */
  introduction: Scalars['String'];
  /**  案件LP名  */
  name: Scalars['String'];
  /**  プロジェクト  */
  project: Project;
  /**
   *  プロジェクトID TODO:uuidに切り替えたら削除する
   * @deprecated change to projectUuid
   */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  案件LPドメイン  */
  siteDomain: Scalars['String'];
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** 匿名認証結果 */
export type LegacyAnonymousAuthResult = {
  __typename?: 'LegacyAnonymousAuthResult';
  /**  firebase custom token  */
  firebaseCustomToken: Scalars['String'];
  /**  firebase customer listen path  */
  firestoreCustomerListenPath: Scalars['String'];
  /**
   *  firebase listen path
   * @deprecated firestoreCustomerListenPath フィールドに移行
   */
  firestoreListenPath: Scalars['String'];
  /**
   *  firestore room listen path
   * @deprecated firestoreRoomUUIDListenPath フィールドに移行
   */
  firestoreRoomListenPath: Scalars['String'];
  /**  firestore room listen path  */
  firestoreRoomUUIDListenPath: Scalars['String'];
  /**  LPの見出し  */
  header: Scalars['String'];
  /**  匿名認証の関連情報  */
  identity: LegacyIdentity;
  /**  LPの案内文  */
  introductionMessage: Scalars['String'];
  /**  realtime database listen path  */
  realtimeDatabaseListenPath: Scalars['String'];
  /**
   *  待機動画ID TODO:uuidに切り替えたら削除する
   * @deprecated change to projectUuid
   */
  waitingMovieId: Scalars['Int'];
  /**  待機動画パス  */
  waitingMoviePath: Scalars['String'];
  /**  待機動画uuid  */
  waitingMovieUuid: Scalars['ID'];
};

/** skyway peer の credential の発行 */
export type LegacyAuthSkywayAnonymouslyInput = {
  /**  peerId  */
  peerId: Scalars['String'];
  /**  ルームキー  */
  roomKey: Scalars['String'];
};

/** 匿名認証の関連情報 */
export type LegacyIdentity = {
  __typename?: 'LegacyIdentity';
  /**  失効期限  */
  expireAt: Scalars['DateTime'];
  /**  firebase email  */
  firebaseEmail?: Maybe<Scalars['String']>;
  /**  firebase refresh token  */
  firebaseRefreshToken: Scalars['String'];
  /**  firebase token  */
  firebaseToken: Scalars['String'];
  /**  firebase token expire  */
  firebaseTokenExpireAt: Scalars['DateTime'];
  /**  firebase uuid  */
  firebaseUuid: Scalars['String'];
  /**
   *  プロジェクトID TODO:uuidに切り替えたら削除する
   * @deprecated change to projectUuid
   */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームキー  */
  roomKey: Scalars['String'];
  /**  ルーム名  */
  roomName: Scalars['String'];
};

/** firebase トークンのリフレッシュ結果 */
export type LegacyRefreshAnonymousAuthTokenResult = {
  __typename?: 'LegacyRefreshAnonymousAuthTokenResult';
  /**  匿名認証の関連情報  */
  identity: LegacyIdentity;
};

/** firebase トークンのリフレッシュ */
export type LegacyRefreshTokenAnonymouslyInput = {
  /**  リフレッシュトークン  */
  refreshToken: Scalars['String'];
  /**  ルームキー  */
  roomKey: Scalars['String'];
};

/** WEBルームのサインイン */
export type LegacySignInConferenceAnonymouslyInput = {
  /**  apiKey  */
  apiKey: Scalars['String'];
};

/** 予約ルームのサインイン */
export type LegacySignInRoomAnonymouslyInput = {
  /**  ルームキー  */
  roomKey: Scalars['String'];
};

/** skyway peer の credential の発行結果 */
export type LegacySkywayAuthResult = {
  __typename?: 'LegacySkywayAuthResult';
  /**  authToken  */
  authToken: Scalars['String'];
  /**  peerId  */
  peerId: Scalars['String'];
  /**  タイムスタンプ  */
  timestamp: Scalars['Int'];
  /**  有効期限  */
  ttl: Scalars['Int'];
};

/** マッチング結果 */
export type MatchingResult = {
  __typename?: 'MatchingResult';
  /**  カスタムトークン  */
  customToken: Scalars['String'];
  /**  firestore の ListenPath  */
  firestoreListenPath: Scalars['String'];
  /**  realtime database の ListenPath  */
  realtimeDatabaseListenPath: Scalars['String'];
  /**  ルームキー  */
  roomKey: Scalars['String'];
};

/** 動画 */
export type Movie = {
  __typename?: 'Movie';
  /**  自動ミュート  */
  autoMicMute?: Maybe<Scalars['Boolean']>;
  /**  カテゴリー  */
  category?: Maybe<MovieCategory>;
  /**  作成日時  */
  createdAt: Scalars['DateTime'];
  /**  動画の長さ（秒）  */
  duration: Scalars['Int'];
  /**
   *  動画ID TODO:uuidに切り替えたら削除する
   * @deprecated change to uuid
   */
  id: Scalars['Int'];
  /**  ラベル  */
  label?: Maybe<Scalars['String']>;
  /**  更新日時  */
  updatedAt: Scalars['DateTime'];
  /**  動画のURL  */
  url?: Maybe<Scalars['URL']>;
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** 動画カテゴリー */
export enum MovieCategory {
  /**  ADT背景動画  */
  AdtBackground = 'ADT_BACKGROUND',
  /**  マイクミュートあり動画  */
  Attention = 'ATTENTION',
  /**  会話終了時に再生される動画  */
  CallEnd = 'CALL_END',
  /**  会話開始時に再生される動画  */
  CallStart = 'CALL_START',
  /**  入室時のエントランスで再生される動画  */
  Entrance = 'ENTRANCE',
  /**  顔検知したときに再生される動画  */
  FaceDetection = 'FACE_DETECTION',
  /**  マイクミュートなし動画  */
  Interaction = 'INTERACTION',
  /**  ボタン検知したときに再生される動画  */
  Invocation = 'INVOCATION',
  /**  待機動画  */
  Offline = 'OFFLINE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addHistoryWithAnswer: Scalars['Boolean'];
  addHistoryWithTemplate: Scalars['Boolean'];
  /** チャットログのメール送信 */
  chatLogMailSender: Scalars['Boolean'];
  /** 検知開始 */
  createInvocation: Scalars['Boolean'];
  /** 検知終了 */
  deleteInvocation: Scalars['Boolean'];
  /** room の document の roomKey 削除 */
  deleteRoomKeyFromRoomDocument: Scalars['Boolean'];
  /** firestore の ReloadRequired を false に変更 */
  disableRoomReloadRequired: Scalars['Boolean'];
  /** Agora の RTC Token を発行する */
  generateAgoraRtcToken: Scalars['JWT'];
  /** Agora の RTM Token を発行する */
  generateAgoraRtmToken: Scalars['JWT'];
  /** Azure AI Serviceの自動応答用のトークンを発行する */
  generateAzureAIServiceAutoResponseToken: AzureAiServiceToken;
  /** Azure AI Service の Token を発行する */
  generateAzureAIServiceToken: AzureAiServiceToken;
  /** Firebase Authentication Custom Token を発行 */
  generateFirebaseCustomToken: Scalars['String'];
  /** Skyway Peer の credential の発行 */
  legacyAuthSkywayAnonymously: LegacySkywayAuthResult;
  /** Firebase トークンのリフレッシュ */
  legacyRefreshTokenAnonymously: LegacyRefreshAnonymousAuthTokenResult;
  /** WEBルームのサインイン */
  legacySignInConferenceAnonymously: LegacyAnonymousAuthResult;
  /** カスタマーでカメラ設定した際にエラーが出たらオペレーターに通知する */
  notifyCameraSelectionError: Scalars['Boolean'];
  /** チャットの投稿 */
  postChat: Scalars['Boolean'];
  /** ADT 接続切れをオペレーターグループに通知する */
  publishADTIsDisconnected: Scalars['Boolean'];
  /** CRM 顧客 id を Pusher に publish */
  publishCRMUserId: Scalars['Boolean'];
  /** チャットの既読 */
  readChat: Scalars['Boolean'];
  readTemplate: Scalars['Boolean'];
  /** invocation が有効な常設ルームに入室する時に DB, RealtimeDB, Firestore に customer のドキュメントを作成する */
  signInRoom: SignInRoomResult;
  /** 常設ルーム入室 */
  signInWithPermanentRoom: Customer;
  /** 予約ルーム入室 */
  signInWithScheduledRoom: Customer;
  /** WEBルーム入室 */
  signInWithWebBasedRoom: Customer;
  /** カスタマーのカメラ設定更新 */
  updateCustomerCameraSetting: Scalars['Boolean'];
};


export type MutationAddHistoryWithAnswerArgs = {
  input: AddHistoryWithAnswerInput;
};


export type MutationAddHistoryWithTemplateArgs = {
  input: AddHistoryWithTemplateInput;
};


export type MutationChatLogMailSenderArgs = {
  input: ChatLogMailSenderInput;
};


export type MutationCreateInvocationArgs = {
  input: CreateInvocationInput;
};


export type MutationDeleteInvocationArgs = {
  input: DeleteInvocationInput;
};


export type MutationDeleteRoomKeyFromRoomDocumentArgs = {
  input: DeleteRoomKeyFromRoomDocumentInput;
};


export type MutationDisableRoomReloadRequiredArgs = {
  input: DisableRoomReloadRequiredInput;
};


export type MutationGenerateAgoraRtcTokenArgs = {
  suffix?: InputMaybe<Scalars['String']>;
};


export type MutationGenerateAgoraRtmTokenArgs = {
  suffix?: InputMaybe<Scalars['String']>;
};


export type MutationLegacyAuthSkywayAnonymouslyArgs = {
  input: LegacyAuthSkywayAnonymouslyInput;
};


export type MutationLegacyRefreshTokenAnonymouslyArgs = {
  input: LegacyRefreshTokenAnonymouslyInput;
};


export type MutationLegacySignInConferenceAnonymouslyArgs = {
  input: LegacySignInConferenceAnonymouslyInput;
};


export type MutationNotifyCameraSelectionErrorArgs = {
  message: Scalars['String'];
};


export type MutationPostChatArgs = {
  input: PostChatInput;
};


export type MutationPublishAdtIsDisconnectedArgs = {
  input: PublishAdtIsDisconnectedInput;
};


export type MutationPublishCrmUserIdArgs = {
  input: PublishCrmUserIdInput;
};


export type MutationReadChatArgs = {
  input: ReadChatInput;
};


export type MutationReadTemplateArgs = {
  input: ReadTemplateInput;
};


export type MutationSignInRoomArgs = {
  input: SignInRoomInput;
};


export type MutationSignInWithScheduledRoomArgs = {
  roomUuid: Scalars['ID'];
};


export type MutationSignInWithWebBasedRoomArgs = {
  apiKey: Scalars['String'];
};


export type MutationUpdateCustomerCameraSettingArgs = {
  input: UpdateCustomerCameraSettingInput;
};

export type PermanentRoomSetting = {
  __typename?: 'PermanentRoomSetting';
  /**  ADT のカスタマーレンダリング機能が有効になっている場合に使用する動画の種類  */
  customerRenderingMovieCategory: CustomerRenderingMovieCategory;
  /**  顔検知動画  */
  faceDetectionMovie?: Maybe<Movie>;
  /**  顔検知動画のUUID  */
  faceDetectionMovieUuid?: Maybe<Scalars['ID']>;
  /**  ボタン検知動画  */
  invocationMovie?: Maybe<Movie>;
  /**  ボタン検知動画のUUID  */
  invocationMovieUuid?: Maybe<Scalars['ID']>;
  /**  ADT のカスタマーレンダリング機能の使用設定  */
  useCustomerRendering: Scalars['Boolean'];
  /**  KIOSKモードの利用設定  */
  useKioskMode: Scalars['Boolean'];
};

/** チャットの投稿 */
export type PostChatInput = {
  /**  chatHistoryID  */
  chatHistoryId?: InputMaybe<Scalars['String']>;
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームID TODO:uuidに切り替えたら削除する  */
  roomId?: InputMaybe<Scalars['Int']>;
  /**  ルームuuid  */
  roomUuid: Scalars['ID'];
  /**  セッションID  */
  sessionId?: InputMaybe<Scalars['String']>;
  /**  テキスト  */
  text: Scalars['String'];
};

/** プロジェクト */
export type Project = {
  __typename?: 'Project';
  /**  ADT背景リソース  */
  adtBackgrounds: Array<AdtBackground>;
  /**  作成日時  */
  createdAt: Scalars['DateTime'];
  /**  グループ  */
  group?: Maybe<Group>;
  /**
   *  グループID TODO:uuidに切り替えたら削除する
   * @deprecated change to groupUuid
   */
  groupId: Scalars['Int'];
  /**  グループuuid  */
  groupUuid: Scalars['ID'];
  /**
   *  プロジェクトID TODO:uuidに切り替えたら削除する
   * @deprecated change to uuid
   */
  id: Scalars['Int'];
  /**  動画  */
  movies: Array<Movie>;
  /**  プロジェクト名  */
  name: Scalars['String'];
  /**  ルーム  */
  rooms: Array<Room>;
  /**  プロジェクト設定  */
  setting: ProjectSetting;
  /**  更新日時  */
  updatedAt: Scalars['DateTime'];
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** プロジェクト設定 */
export type ProjectSetting = {
  __typename?: 'ProjectSetting';
  /**  作成日時  */
  createdAt: Scalars['DateTime'];
  /**  GoogleアナリティクスID  */
  gaTagID?: Maybe<Scalars['String']>;
  /**
   *  プロジェクト設定ID TODO:uuidに切り替えたら削除する
   * @deprecated change to uuid
   */
  id: Scalars['Int'];
  /**
   *  プロジェクトID TODO:uuidに切り替えたら削除する
   * @deprecated change to projectUuid
   */
  projectID: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  翻訳機能の利用設定  */
  translation: TranslationMode;
  /**  更新日時  */
  updatedAt: Scalars['DateTime'];
  /**  ボタン検知使用有無  */
  useButtonDetection: Scalars['Boolean'];
  /**  CRM連携使用有無  */
  useCrm: Scalars['Boolean'];
  /**  顔検知使用有無  */
  useFaceDetection: Scalars['Boolean'];
  /**  カスタマーのマイク接続状態の警告文の使用有無  */
  usePermanentRoomDisplayMicWarning: Scalars['Boolean'];
  /**  カスタマー(常設ルーム)のSession Replayの使用有無  */
  usePermanentRoomSessionReplay: Scalars['Boolean'];
  /**  カスタマー(予約ルーム)のSession Replayの使用有無  */
  useScheduledRoomSessionReplay: Scalars['Boolean'];
  /**  カスタマー(WEBルーム)のSession Replayの使用有無  */
  useWebBasedRoomSessionReplay: Scalars['Boolean'];
  /**  uuid  */
  uuid: Scalars['ID'];
  /**  音声分析の使用有無  */
  voiceAnalyzer: Scalars['Boolean'];
};

/** ADT 接続切れをオペレーターグループに通知する */
export type PublishAdtIsDisconnectedInput = {
  /**  ルームキー  */
  roomKey: Scalars['String'];
};

export type PublishCrmUserIdInput = {
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  userId: Scalars['String'];
};

/** 顔検知の離脱 event を Pusher に publish */
export type PublishCancelInvocationInput = {
  /**  ルームキー  */
  roomKey: Scalars['String'];
  /**  source  */
  source: Source;
};

/** 検知 event を Pusher に publish */
export type PublishInvocationInput = {
  /**  ルームキー  */
  roomKey: Scalars['String'];
  /**  source  */
  source: Source;
};

export type Query = {
  __typename?: 'Query';
  /** fetchCustomer */
  customer?: Maybe<Customer>;
  /** 埋め込みページのドメイン取得 */
  embedDomainWithWebBasedRoom: Scalars['String'];
  /** skyWay Auth Token 取得 */
  skyWayAuthToken: Scalars['String'];
};


export type QueryEmbedDomainWithWebBasedRoomArgs = {
  apiKey: Scalars['String'];
};


export type QuerySkyWayAuthTokenArgs = {
  roomID?: InputMaybe<Scalars['Int']>;
  roomKey: Scalars['String'];
};

/** チャットの既読 */
export type ReadChatInput = {
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームID TODO:uuidに切り替えたら削除する  */
  roomId?: InputMaybe<Scalars['Int']>;
  /**  ルームuuid  */
  roomUuid: Scalars['ID'];
  /**  セッションID  */
  sessionId: Scalars['String'];
};

/** カスタマーによるテンプレートの既読関連 */
export type ReadTemplateInput = {
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームID TODO:uuidに切り替えたら削除する  */
  roomId?: InputMaybe<Scalars['Int']>;
  /**  ルームuuid  */
  roomUuid: Scalars['ID'];
  /**  セッションID  */
  sessionId: Scalars['String'];
  /**  templateHistoryID  */
  templateHistoryId: Scalars['String'];
};

/** ルーム */
export type Room = {
  __typename?: 'Room';
  /**  割り当てられたシナリオ  */
  assignedScenario?: Maybe<Scenario>;
  /**
   *  割り当てられたシナリオUUID
   * @deprecated assignedScenario フィールドに移行
   */
  assignedScenarioUuid?: Maybe<Scalars['ID']>;
  /**  作成日時  */
  createdAt: Scalars['DateTime'];
  /**
   *  customer の FPS の設定
   * @deprecated customerStreamQuality フィールドに移行
   */
  customerFPS: RoomFps;
  /**  customer の配信品質  */
  customerStreamQuality: StreamQuality;
  /**  listenPath  */
  documentListenPath: Scalars['String'];
  /**  顔検知してから通知するまでの時間（ミリ秒）*常設ルームのみの設定  */
  faceDetectionAlertDuration?: Maybe<Scalars['Int']>;
  /**  顔検知範囲の縦幅, nullの場合はフロントで画面サイズの100%とみなす *常設ルームのみの設定  */
  faceDetectionBoxHeight?: Maybe<Scalars['Float']>;
  /**  顔検知範囲の横幅, nullの場合はフロントで画面サイズの100%とみなす *常設ルームのみの設定  */
  faceDetectionBoxWidth?: Maybe<Scalars['Float']>;
  /**  顔検知範囲の始点(x) *常設ルームのみの設定  */
  faceDetectionBoxX?: Maybe<Scalars['Float']>;
  /**  顔検知範囲の始点(y) *常設ルームのみの設定  */
  faceDetectionBoxY?: Maybe<Scalars['Float']>;
  /**  顔検知を行う頻度（FPS）  */
  faceDetectionFps?: Maybe<Scalars['Int']>;
  /**
   *  ルームID TODO:uuidに切り替えたら削除する
   * @deprecated change to uuid
   */
  id: Scalars['Int'];
  /**  ルームキー  */
  key: Scalars['String'];
  /**
   *  listenPath
   * @deprecated documentListenPath フィールドに移行
   */
  listenPath: Scalars['String'];
  /**  ルーム名  */
  name: Scalars['String'];
  /**  ルームの待機動画 */
  offlineMovie?: Maybe<Movie>;
  /**  常設ルーム設定  */
  permanentRoomSetting?: Maybe<PermanentRoomSetting>;
  /**
   *  プロジェクトID TODO:uuidに切り替えたら削除する
   * @deprecated change to projectUuid
   */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームの RTC に使用する SDK  */
  rtcSDK: RoomRtcSdk;
  /**  ルームタイプ  */
  type: RoomType;
  /**  更新日時  */
  updatedAt: Scalars['DateTime'];
  /**  ADT のカスタマーレンダリング機能の使用設定  */
  useCustomerRendering?: Maybe<Scalars['Boolean']>;
  /**  顔マスク機能の利用設定 */
  useFaceMask?: Maybe<Scalars['Boolean']>;
  /**  UUID  */
  uuid: Scalars['ID'];
};

/** Room の FPS の設定 */
export type RoomFps = {
  __typename?: 'RoomFPS';
  /**  room の FPS 値  */
  fps?: Maybe<Scalars['Int']>;
  /**  FPS のモード  */
  mode: RoomFpsMode;
};

/** FPS の設定 */
export enum RoomFpsMode {
  /**  FPS 値を自動で調整する  */
  Auto = 'AUTO',
  /**  FPS 値を手動で設定する  */
  Manual = 'MANUAL'
}

/** ルームの RTC に使用する SDK */
export enum RoomRtcSdk {
  Agora = 'AGORA',
  Skyway = 'SKYWAY'
}

/** ルームステータス */
export enum RoomStatus {
  /**  ルームに入室せず離脱  */
  AlreadyExitedRoom = 'ALREADY_EXITED_ROOM',
  /**  ルーム入室中  */
  EnterRoom = 'ENTER_ROOM',
  /**  ルームから退室  */
  LeaveRoom = 'LEAVE_ROOM',
  /**  ルーム未入室  */
  WaitRoom = 'WAIT_ROOM'
}

/** ルームタイプ */
export enum RoomType {
  /**  CoTルーム  */
  Cot = 'COT',
  /**  常設ルーム  */
  Permanent = 'PERMANENT',
  /**  予約ルーム  */
  Scheduled = 'SCHEDULED',
  /**  WEBルーム  */
  Webbased = 'WEBBASED'
}

/** シナリオ */
export type Scenario = {
  __typename?: 'Scenario';
  /**  ジェスチャーマップ  */
  motions: Array<ScenarioMotion>;
  /**  シナリオ名  */
  name: Scalars['String'];
  /**  シナリオuuid  */
  uuid: Scalars['ID'];
};

/** アバターのジェスチャー */
export type ScenarioMotion = {
  __typename?: 'ScenarioMotion';
  /**  ジェスチャータイプ  */
  type: ScenarioMotionType;
  /**  ジェスチャー URL  */
  url: Scalars['URL'];
};

/** アバターのジェスチャータイプ */
export enum ScenarioMotionType {
  Bow = 'BOW',
  Byebye = 'BYEBYE',
  ChatIcon = 'CHAT_ICON',
  ChatWait = 'CHAT_WAIT',
  Nod = 'NOD',
  None = 'NONE',
  Talk = 'TALK',
  Wait = 'WAIT'
}

/** 常設ルーム入室 */
export type SignInRoomInput = {
  /**  ルームキー  */
  roomKey: Scalars['String'];
  /**  ルームステータス  */
  roomStatus: RoomStatus;
};

/** Source */
export enum Source {
  /**  自動応答検知  */
  AutoResponseCallDetection = 'autoResponseCallDetection',
  /**  ボタン検知  */
  ButtonDetection = 'buttonDetection',
  /**  顔検知  */
  FaceDetection = 'faceDetection'
}

export type StreamQuality = {
  __typename?: 'StreamQuality';
  /**  ビットレート  */
  bitRate: Scalars['Int'];
  /**  FPS のモード  */
  fpsMode: RoomFpsMode;
  /**  room の FPS 値  */
  frameRate?: Maybe<Scalars['Int']>;
};

/** 翻訳機能の利用設定 */
export enum TranslationMode {
  /**  全ての機能を使用  */
  All = 'ALL',
  /**  無効  */
  Disabled = 'DISABLED'
}

/** カスタマーのカメラ設定更新 */
export type UpdateCustomerCameraSettingInput = {
  /**  カメラのデバイスリスト TODO: フロントが対応したら必須にする  */
  devices?: InputMaybe<Array<CustomerCameraDeviceInput>>;
  /**  メインカメラID  */
  mainCameraDeviceId?: InputMaybe<Scalars['String']>;
  /**  カメラのオリジン解像度(height)  */
  originHeight: Scalars['Int'];
  /**  カメラのオリジン解像度(width)  */
  originWidth: Scalars['Int'];
};

/** ユーザータイプ */
export enum UserType {
  /**  カスタマー  */
  Customer = 'CUSTOMER',
  /**  オペレーター  */
  Operator = 'OPERATOR'
}

/** チャットログのメール送信 */
export type ChatLogMailSenderInput = {
  /**  apiKey  */
  apiKey: Scalars['String'];
  /**  チャンネルID  */
  channelId: Scalars['String'];
  /**  チャットボットID  */
  chatbotId: Scalars['String'];
  /**  プロジェクトID TODO:uuidに切り替えたら削除する  */
  projectId?: InputMaybe<Scalars['Int']>;
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
};

/** 常設ルーム入室結果 */
export type SignInRoomResult = {
  __typename?: 'signInRoomResult';
  /**  realtime database listen path  */
  realtimeDatabaseListenPath: Scalars['String'];
};
