export enum CustomerOnlineStatus {
  Offline = 0,
  Online,
}

export enum CustomerRoomStatus {
  BeforeEntering = 0,
  DuringEntering = 1,
  AfterEntering = 2,
  AlreadyExited = 3,
}

export type CustomerRealtimeDatabaseData = {
  onlineStatus: CustomerOnlineStatus;
  projectUuid: string;
  roomKey: string;
  roomName: string;
  lastChanged: number;
  customerRoomStatus: number;
};
