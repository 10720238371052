import { useEffect, useMemo, useState } from 'react';
import { createMediaStream } from '../../LocalMediaStreamProvider.utils';
import { useLocalAudioTrack } from '../useLocalAudioTrack';
import { useLocalVideoTrack } from '../useLocalVideoTrack';
import { UseMainStreamProps } from './useMainStream.types';

export const useMainStream = ({
  selectedAudioDeviceId,
  selectedVideoDeviceId,
  videoDeviceList,
  isAnonymous = false,
  localMaskedVideoTrack,
  handleError,
  isFaceMaskEnabled,
}: UseMainStreamProps) => {
  // NOTE: SkyWay を通じて送信する stream
  const [mainStream, setMainStream] = useState<MediaStream | null>(null);
  // NOTE: 顔検出やセルフビューで使う stream
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);

  const { audioTrack } = useLocalAudioTrack({ selectedAudioDeviceId, handleError });
  const { videoTrack } = useLocalVideoTrack({ selectedVideoDeviceId, handleError, videoDeviceList });
  // TODO: 顔マスク有効の場合は初回で生のvideoTrackをpublishしないほうがいい？とはいえ顔検出の立ち上がりまで映像配信されないのも困る？
  const mainStreamVideoTrack = useMemo(
    () => (isFaceMaskEnabled && localMaskedVideoTrack ? localMaskedVideoTrack : videoTrack),
    [isFaceMaskEnabled, localMaskedVideoTrack, videoTrack],
  );

  useEffect(() => {
    if (audioTrack === undefined || audioTrack === null) return;
    // audioTrackがある時
    const videoTrackData = videoTrack && videoTrack !== 'none' ? videoTrack : null;
    const newStream = createMediaStream({
      audioTrack,
      videoTrack: videoTrackData,
      videoContentHint: 'detail',
    });
    setLocalStream(newStream);
    const newMainStream = createMediaStream({
      audioTrack,
      // HACK: trackをcloneして設定している。trackをcloneせずそのまま使うと生の映像をマスク映像に差し替える際に
      // replaceVideoStream時のreleaseOldStreamで生映像のvideoTrackが開放されてしまうため
      videoTrack: mainStreamVideoTrack && mainStreamVideoTrack !== 'none' ? mainStreamVideoTrack.clone() : null,
      videoContentHint: 'detail',
    });
    setMainStream(newMainStream);
  }, [audioTrack, isAnonymous, videoTrack, mainStreamVideoTrack]);

  return {
    mainStream,
    localStream,
  };
};
