import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WebRoomState = {
  header: string;
  introductionMessage: string;
  informationMessage?: string;
  entranceImage?: string;
  entranceMovie?: string;
};

const initialState: WebRoomState = {
  header: '',
  introductionMessage: '',
};

export const webRoomSlice = createSlice({
  name: 'webRoom',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<string>) => {
      state.header = action.payload;
    },
    setIntroductionMessage: (state, action: PayloadAction<string>) => {
      state.introductionMessage = action.payload;
    },
    resetInformationMessage: (state) => {
      state.informationMessage = '';
    },
    setInformationMessage: (state, action: PayloadAction<string | undefined>) => {
      state.informationMessage = action.payload;
    },
    setEntranceImage: (state, action: PayloadAction<string>) => {
      state.entranceImage = action.payload;
    },
    setEntranceMovie: (state, action: PayloadAction<string>) => {
      state.entranceMovie = action.payload;
    },
  },
});

export const {
  setHeader,
  setIntroductionMessage,
  setInformationMessage,
  resetInformationMessage,
  setEntranceImage,
  setEntranceMovie,
} = webRoomSlice.actions;

export default webRoomSlice.reducer;
