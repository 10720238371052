import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { TalkMode } from '../../../types/chatbot/scenario.types';

export type TalkModeState = {
  talkMode: TalkMode;
};

const initialState: TalkModeState = {
  talkMode: 'message',
};

export const talkModeSlice = createSlice({
  name: 'talkMode',
  initialState,
  reducers: {
    updateTalkMode: (state, action: PayloadAction<TalkMode>) => {
      state.talkMode = action.payload;
    },
  },
});

export const { updateTalkMode } = talkModeSlice.actions;

export default talkModeSlice.reducer;
