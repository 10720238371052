import { useCallback, useState } from 'react';
import { createMediaStream, getDisplayMediaTracks } from '../../LocalMediaStreamProvider.utils';

export const useScreenSharingStream = () => {
  const [screenSharingStream, setScreenSharingStream] = useState<MediaStream | null>(null);

  const createScreenSharingStream = useCallback(() => {
    (async () => {
      const { audioTrack, videoTrack } = await getDisplayMediaTracks({ video: true, audio: true });
      const newStream =
        audioTrack !== null || videoTrack !== null
          ? createMediaStream({
              audioTrack,
              videoTrack,
              videoContentHint: 'motion',
            })
          : null;

      setScreenSharingStream((prevState) => {
        if (prevState !== null) {
          const tracks = prevState.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
        return newStream;
      });

      // eslint-disable-next-line no-console
    })().catch(console.error);
  }, []);

  const destroyScreenSharingStream = useCallback(() => {
    setScreenSharingStream((prevState) => {
      if (prevState !== null) {
        const tracks = prevState.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
      return null;
    });
  }, []);

  return { screenSharingStream, createScreenSharingStream, destroyScreenSharingStream };
};
