import { useMemo } from 'react';
import { useMainStream } from '../useMainStream';
import { useScreenSharingStream } from '../useScreenSharingStream';
import { useOperatorVideoTrack } from '../useOperatorVideoTrack';
import { UseLocalMediaStreamProviderProps } from './useLocalMediaStreamProvider.types';
import { useLocalMaskedVideoTrack } from '../useLocalMaskedVideoTrack';

// eslint-disable-next-line max-lines-per-function
export function useLocalMediaStreamProvider({
  selectedAudioDeviceId,
  selectedVideoDeviceId,
  operatorVideoDeviceId,
  videoDeviceList,
  isAnonymous = false,
  handleError,
}: UseLocalMediaStreamProviderProps) {
  const { localMaskedVideoTrack, updateLocalMaskedVideoTrack, isFaceMaskEnabled, updateFaceMaskEnabled } =
    useLocalMaskedVideoTrack();
  const { mainStream, localStream } = useMainStream({
    selectedAudioDeviceId,
    selectedVideoDeviceId,
    videoDeviceList,
    isAnonymous,
    isFaceMaskEnabled,
    localMaskedVideoTrack,
    handleError,
  });

  const { screenSharingStream, createScreenSharingStream, destroyScreenSharingStream } = useScreenSharingStream();

  const { operatorVideoTrack, updateIsADTCameraVertical } = useOperatorVideoTrack({
    adtCameraDeviceId: operatorVideoDeviceId,
  });

  const value = useMemo(
    () => ({
      mainStream,
      localStream,
      screenSharingStream,
      operatorVideoTrack,
      createScreenSharingStream,
      destroyScreenSharingStream,
      updateIsADTCameraVertical,
      updateLocalMaskedVideoTrack,
      isFaceMaskEnabled,
      updateFaceMaskEnabled,
    }),
    [
      mainStream,
      localStream,
      screenSharingStream,
      operatorVideoTrack,
      createScreenSharingStream,
      destroyScreenSharingStream,
      updateIsADTCameraVertical,
      updateLocalMaskedVideoTrack,
      isFaceMaskEnabled,
      updateFaceMaskEnabled,
    ],
  );
  return { value };
}
