import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';
import type { AppProps } from 'next/app';
import * as Sentry from '@sentry/react';
import { RecoilRoot } from 'recoil';
import { GoogleAnalyticsAsyncScript, sentryInitInBrowser } from '@avita-co-jp/react-components';
import { SkyWayProvider } from '@avita-co-jp/skyway-provider';
import { store } from '../store';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { ApolloAuthProvider } from '../providers/ApolloAuthProvider';
import { DefaultLayout } from '../layouts';
import '../styles/notify.scss';

sentryInitInBrowser({
  ignoreErrors: [
    // SkyWay Analytics のエラーは無視する
    "Failed to execute 'getStats' on 'RTCPeerConnection': There is no sender or receiver for the track.",
  ],
});
const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [isReady, setIsReady] = useState<boolean>(false);

  // NOTE: router.isReadyを直接参照すると初回レンダリングでエラーになる
  // That's required because the first render should match the initial render of the server.
  // When you run browser only code inside useEffect, it will happen after hydration.
  useEffect(() => {
    if (router.isReady) {
      setIsReady(true);
    }
  }, [router.isReady]);

  return (
    <>
      <GoogleAnalyticsAsyncScript googleAnalyticsId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
      {/* OPTIMIZE: レンダリングのパフォーマンスが悪くなるので、router.isReadyをapp.page.tsxで判定しないようにする */}
      {isReady && (
        <ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
            <ToastContainer newestOnTop closeOnClick draggable />
            <RecoilRoot>
              <Provider store={store}>
                <ApolloAuthProvider>
                  <SkyWayProvider>
                    <DefaultLayout>
                      <Component {...pageProps} locale={router.locale} />
                    </DefaultLayout>
                  </SkyWayProvider>
                </ApolloAuthProvider>
              </Provider>
            </RecoilRoot>
          </Sentry.ErrorBoundary>
        </ErrorBoundary>
      )}
    </>
  );
};

export default MyApp;
