import { createSlice } from '@reduxjs/toolkit';

export type DialogState = {
  isOpenDeviceSetting: boolean;
  isOpenRoomExitConfirm: boolean;
  isOpenChatbotExitConfirm: boolean;
  isOpenMicWarning: boolean;
  isOpenLogin: boolean;
  isOpenRoomSetting: boolean;
  isOpenAlert: boolean;
  isOpenSelective: boolean;
  isOpenAlertForIOS?: boolean;
  isOpenNetworkError?: boolean;
  isOpenNetworkReconnecting?: boolean;
  isOpenTranslation?: boolean;
  isOpenChatbot?: boolean;
};

const initialState: DialogState = {
  isOpenDeviceSetting: false,
  isOpenMicWarning: false,
  isOpenRoomExitConfirm: false,
  isOpenChatbotExitConfirm: false,
  isOpenLogin: false,
  isOpenAlert: false,
  isOpenRoomSetting: false,
  isOpenSelective: false,
  isOpenAlertForIOS: false,
  isOpenNetworkError: false,
  isOpenNetworkReconnecting: false,
  isOpenTranslation: false,
  isOpenChatbot: false,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDeviceSetting: (state) => {
      state.isOpenDeviceSetting = true;
    },
    closeDeviceSetting: (state) => {
      state.isOpenDeviceSetting = false;
    },
    openRoomExitConfirm: (state) => {
      state.isOpenRoomExitConfirm = true;
    },
    closeRoomExitConfirm: (state) => {
      state.isOpenRoomExitConfirm = false;
    },
    openChatbotExitConfirm: (state) => {
      state.isOpenChatbotExitConfirm = true;
    },
    closeChatbotExitConfirm: (state) => {
      state.isOpenChatbotExitConfirm = false;
    },
    openMicWarning: (state) => {
      state.isOpenMicWarning = true;
    },
    closeMicWarning: (state) => {
      state.isOpenMicWarning = false;
    },
    openLogin: (state) => {
      state.isOpenLogin = true;
    },
    closeLogin: (state) => {
      state.isOpenLogin = false;
    },
    openRoomSetting: (state) => {
      state.isOpenRoomSetting = true;
    },
    closeRoomSetting: (state) => {
      state.isOpenRoomSetting = false;
    },
    openAlert: (state) => {
      state.isOpenAlert = true;
    },
    closeAlert: (state) => {
      state.isOpenAlert = false;
    },
    openSelective: (state) => {
      state.isOpenSelective = true;
    },
    closeSelective: (state) => {
      state.isOpenSelective = false;
    },
    openAlertForIOS: (state) => {
      state.isOpenAlertForIOS = true;
    },
    closeAlertForIOS: (state) => {
      state.isOpenAlertForIOS = false;
    },
    openNetworkError: (state) => {
      state.isOpenNetworkError = true;
    },
    closeNetworkError: (state) => {
      state.isOpenNetworkError = false;
    },
    openNetworkReconnecting: (state) => {
      state.isOpenNetworkReconnecting = true;
    },
    closeNetworkReconnecting: (state) => {
      state.isOpenNetworkReconnecting = false;
    },
    openTranslation: (state) => {
      state.isOpenTranslation = true;
    },
    closeTranslation: (state) => {
      state.isOpenTranslation = false;
    },
    openChatbot: (state) => {
      state.isOpenChatbot = true;
    },
    closeChatbot: (state) => {
      state.isOpenChatbot = false;
    },
  },
});

export const {
  openDeviceSetting,
  closeDeviceSetting,
  openRoomExitConfirm,
  closeRoomExitConfirm,
  openChatbotExitConfirm,
  closeChatbotExitConfirm,
  openMicWarning,
  closeMicWarning,
  openLogin,
  closeLogin,
  openRoomSetting,
  closeRoomSetting,
  openAlert,
  closeAlert,
  openSelective,
  closeSelective,
  openAlertForIOS,
  closeAlertForIOS,
  openNetworkError,
  closeNetworkError,
  openNetworkReconnecting,
  closeNetworkReconnecting,
  openTranslation,
  closeTranslation,
  openChatbot,
  closeChatbot,
} = dialogSlice.actions;
export default dialogSlice.reducer;
