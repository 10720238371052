import { LocalMediaStreamProviderProps } from './LocalMediaStreamProvider.types';
import { LocalMediaStreamContext } from './LocalMediaStreamContext';
import { useLocalMediaStreamProvider } from './hooks';
import { useMediaDeviceContext } from '../MediaDeviceProvider';

export const LocalMediaStreamProvider = ({ children, isAnonymous, handleError }: LocalMediaStreamProviderProps) => {
  const { selectedAudioDeviceId, selectedVideoDeviceId, operatorVideoDeviceId, videoDeviceList } =
    useMediaDeviceContext();
  const { value } = useLocalMediaStreamProvider({
    selectedAudioDeviceId,
    selectedVideoDeviceId,
    operatorVideoDeviceId,
    isAnonymous,
    handleError,
    videoDeviceList,
  });

  return <LocalMediaStreamContext.Provider value={value}>{children}</LocalMediaStreamContext.Provider>;
};
