import { ApolloLink, HttpLink, Observable, Operation, type RequestHandler } from '@apollo/client';
import { GRAPHQL_AUTHORIZATION_ERROR_MESSAGE } from '../../constants/Errors';
import { fetchToken } from './fetchToken';

const hostName = `${process.env.NEXT_PUBLIC_API_HOST}/v1/customer`;
const hostNameForChatbot = `${process.env.NEXT_PUBLIC_API_HOST}/v1/chatbot`;

export const operationNamesForChatbot = ['startScenario', 'branchSelection', 'inputResult', 'talkToChatGPT'];

export function isChatbotOperation(operation: Operation) {
  return operationNamesForChatbot.includes(operation.operationName);
}

export const httpLink = HttpLink.split(
  isChatbotOperation,
  new HttpLink({ uri: hostNameForChatbot }),
  new HttpLink({ uri: hostName }),
);

// NOTE: monorepo での共通化を見据えてauthLinkからredux依存を除く
// TODO: monorepoに移行する
export function generateAuthLinkRequestHandler(fetchTokenFunction: () => string | undefined): RequestHandler {
  return (operation, forward) => {
    const token = fetchTokenFunction();
    if (token === undefined) {
      return new Observable((observer) => {
        observer.error(new Error(GRAPHQL_AUTHORIZATION_ERROR_MESSAGE));
      });
    }
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));
    return forward(operation);
  };
}

export const authLink = new ApolloLink(generateAuthLinkRequestHandler(fetchToken));
