import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project, Room } from '../../../types';

// TODO: UUID対応 id の削除 (roomId: Room.id, skyway 関連が先)
// FIXME: roomKeyのことをidと呼ぶので良いだろうか
export type CustomerState = {
  firebaseUuid?: string;
  name?: string;
  selectedProject?: Project;
  selectedRoomId?: string; // NOTE: idはroomKeyのこと（ADDに準じる）
  conferenceRoomId?: string; // NOTE: idはroomKeyのこと（ADDに準じる）
  selectedRoom?: Room;
};

const initialState: CustomerState = {};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomerState: () => initialState,
    updateCustomerState: (state, action: PayloadAction<CustomerState | undefined>) => ({
      ...state,
      ...action.payload,
    }),
    updateSelectedRoom: (state, action: PayloadAction<Room | undefined>) => {
      state.selectedRoom = action.payload;
    },
    updateSelectedProject: (state, action: PayloadAction<Project | undefined>) => {
      state.selectedProject = action.payload;
    },
    updateSelectRoomId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedRoomId = action.payload;
    },
    updateConferenceRoomId: (state, action: PayloadAction<string | undefined>) => {
      state.conferenceRoomId = action.payload;
    },
  },
});

export const {
  resetCustomerState,
  updateCustomerState,
  updateSelectedRoom,
  updateSelectedProject,
  updateSelectRoomId,
  updateConferenceRoomId,
} = customerSlice.actions;

export default customerSlice.reducer;
