import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type FaceDetectionSettingsState = {
  alertDuration: number;
  alertDismissDuration: number;
  box: FaceDetectionFilterBox;
  fps: number;
};

export type FaceDetectionFilterBox = {
  x: number;
  y: number;
  width: number | null;
  height: number | null;
};

const initialState: FaceDetectionSettingsState = {
  alertDuration: 2000,
  alertDismissDuration: 5000,
  box: {
    x: 0,
    y: 0,
    width: null,
    height: null,
  },
  fps: 5,
};

export const faceDetectionSettingsSlice = createSlice({
  name: 'faceDetectionSettings',
  initialState,
  reducers: {
    updateFaceDetectionSettings: (state, action: PayloadAction<Partial<FaceDetectionSettingsState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateFaceDetectionSettings } = faceDetectionSettingsSlice.actions;

export default faceDetectionSettingsSlice.reducer;
