// TODO: テストを追加する

import { useCallback, useState } from 'react';

export const useUpdateAudioDevice = () => {
  const [audioDeviceId, setAudioDeviceId] = useState<string | null | undefined>(undefined);

  const updateAudioDeviceId = useCallback((deviceId: string | null) => {
    setAudioDeviceId(deviceId);
  }, []);

  return { audioDeviceId, updateAudioDeviceId };
};
