import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverlayMovie } from '../../../types/movie';

export type PayloadOverlayMovie = Pick<OverlayMovie, 'id' | 'uuid' | 'url'>;
export type StoredOverlayMovie = Pick<OverlayMovie, 'id' | 'uuid' | 'url' | 'duration'>;

export type WaitingMovieState = {
  waitingMovie?: StoredOverlayMovie;
  invocationWaitingMovie?: StoredOverlayMovie;
  faceDetectionWaitingMovie?: StoredOverlayMovie;
  transitionInMovie?: StoredOverlayMovie;
  transitionOutMovie?: StoredOverlayMovie;
};

const initialState: WaitingMovieState = {
  waitingMovie: undefined,
  invocationWaitingMovie: undefined,
  faceDetectionWaitingMovie: undefined,
  transitionInMovie: undefined,
  transitionOutMovie: undefined,
};

// TODO: UUID対応 id の削除 (movieId)
export const waitingMovieSlice = createSlice({
  name: 'waitingMovie',
  initialState,
  reducers: {
    resetWaitingMovie: () => initialState,
    updateWaitingMovie: (state, action: PayloadAction<PayloadOverlayMovie>) => {
      state.waitingMovie = {
        id: action.payload.id,
        uuid: action.payload.uuid,
        url: action.payload.url,
        duration: 0,
      };
    },
    updateInvocationWaitingMovie: (state, action: PayloadAction<PayloadOverlayMovie>) => {
      state.invocationWaitingMovie = {
        id: action.payload.id,
        uuid: action.payload.uuid,
        url: action.payload.url,
        duration: 0,
      };
    },
    updateFaceDetectionWaitingMovie: (state, action: PayloadAction<PayloadOverlayMovie>) => {
      state.faceDetectionWaitingMovie = {
        id: action.payload.id,
        uuid: action.payload.uuid,
        url: action.payload.url,
        duration: 0,
      };
    },
    updateTransitionInMovie: (state, action: PayloadAction<PayloadOverlayMovie>) => {
      state.transitionInMovie = {
        id: action.payload.id,
        uuid: action.payload.uuid,
        url: action.payload.url,
        duration: 0,
      };
    },
    updateTransitionOutMovie: (state, action: PayloadAction<PayloadOverlayMovie>) => {
      state.transitionOutMovie = {
        id: action.payload.id,
        uuid: action.payload.uuid,
        url: action.payload.url,
        duration: 0,
      };
    },
  },
});

export const {
  resetWaitingMovie,
  updateWaitingMovie,
  updateInvocationWaitingMovie,
  updateFaceDetectionWaitingMovie,
  updateTransitionInMovie,
  updateTransitionOutMovie,
} = waitingMovieSlice.actions;

export default waitingMovieSlice.reducer;
