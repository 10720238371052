import styles from './PageError.module.scss';
import { ErrorContent } from '../../atoms/ErrorContent';

export const PageError = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.pageError}>
      <ErrorContent status="error">
        <p>アプリケーションでエラーが発生しました。</p>
        <p>リロードして再度お試しください。</p>
      </ErrorContent>
    </div>
  </div>
);
