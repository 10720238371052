import { toast, ToastContent, ToastOptions } from 'react-toastify';

export interface CustomToastOptions extends ToastOptions {
  unique?: boolean;
}

const defaultOptions: ToastOptions = {
  position: 'top-center',
};

const buildOptions = (content: ToastContent, options: CustomToastOptions = {}) => {
  const notifyOptions: CustomToastOptions = {
    ...defaultOptions,
    ...options,
  };

  if (notifyOptions.unique && !notifyOptions.toastId) {
    // NOTE: uniqueが有効でtoastIdが指定されていない場合はコンテンツの文字列をtoastIdとする
    notifyOptions.toastId = content?.toString();
  }
  return notifyOptions;
};

export const notify = {
  info: (content: ToastContent, options: CustomToastOptions | undefined = undefined) => {
    toast.info(content, buildOptions(content, options));
  },
  success: (content: ToastContent, options: CustomToastOptions | undefined = undefined) => {
    toast.success(content, buildOptions(content, options));
  },
  warning: (content: ToastContent, options: CustomToastOptions | undefined = undefined) => {
    toast.warning(content, buildOptions(content, options));
  },
  error: (content: ToastContent, options: CustomToastOptions | undefined = undefined) => {
    toast.error(content, buildOptions(content, options));
  },
  default: (content: ToastContent, options: CustomToastOptions | undefined = undefined) => {
    toast(content, buildOptions(content, options));
  },
  dark: (content: ToastContent, options: CustomToastOptions | undefined = undefined) => {
    toast.dark(content, buildOptions(content, options));
  },
};
