import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ScenarioInstanceTypeCustomEnum,
  type ScenarioStatus,
  ScenarioStatusEnum,
  type ScenarioDataCustom,
  type UnHandledInstancesState,
} from '../../../types/chatbot/scenario.types';

const initialState: UnHandledInstancesState = {
  unHandledInstances: undefined,
  scenarioStatus: ScenarioStatusEnum.INITIAL,
};

export const unHandledInstancesSlice = createSlice({
  name: 'unHandledInstances',
  initialState,
  reducers: {
    resetUnHandledInstances: () => initialState,
    updateUnHandledScenarioInstances: (state, action: PayloadAction<ScenarioDataCustom[]>) => {
      if (state.scenarioStatus !== ScenarioStatusEnum.IN_PROGRESS)
        state.scenarioStatus = ScenarioStatusEnum.IN_PROGRESS;
      state.unHandledInstances = action.payload;
    },
    updateUnHandledScenarioStatus: (state, action: PayloadAction<ScenarioStatus>) => {
      state.scenarioStatus = action.payload;
      if (action.payload !== ScenarioStatusEnum.IN_PROGRESS) state.unHandledInstances = undefined;
    },
    removeFirstUnHandledScenarioInstance: (state) => {
      if (!state.unHandledInstances) return;
      state.unHandledInstances = state.unHandledInstances.slice(1);
    },
    setAfterEndOfScenarioInstance: (state, action: PayloadAction<{ message: string }>) => {
      state.unHandledInstances = [
        {
          instanceType: ScenarioInstanceTypeCustomEnum.AvatarUtterance,
          params: {
            displayText: action.payload.message,
          },
          nodeId: '-1',
        },
        {
          instanceType: 'close_scenario',
          params: {},
          nodeId: '-2',
        },
      ];
    },
  },
});

export const {
  resetUnHandledInstances,
  updateUnHandledScenarioInstances,
  removeFirstUnHandledScenarioInstance,
  setAfterEndOfScenarioInstance,
  updateUnHandledScenarioStatus,
} = unHandledInstancesSlice.actions;

export default unHandledInstancesSlice.reducer;
