import { useCallback } from 'react';
import { useNotifyCameraSelectionErrorMutation } from '../../../../../graphql/mutation.generated';
import type { CameraSelectionErrorHandler } from './useNotifyCameraSelectionError.types';

export function useNotifyCameraSelectionError(): CameraSelectionErrorHandler {
  const [notifyCameraSelectionError] = useNotifyCameraSelectionErrorMutation();
  const handleNotifyCameraSelectionError = useCallback(
    async (message: string) => {
      await notifyCameraSelectionError({ variables: { message } });
    },
    [notifyCameraSelectionError],
  );

  return { handleNotifyCameraSelectionError };
}
