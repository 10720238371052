import { useCallback, useState } from 'react';
import { UserMediaVideoDevices } from '../../../../types/MediaDevice';

export function useFindMediaVideoDevices() {
  const [videoDeviceList, setDeviceList] = useState<UserMediaVideoDevices>({
    currentVideoInDevices: null, // 初期値をnullにして初期表示時とデバイスが空の場合を区別する
  });
  const findMediaVideoDevices = useCallback(async () => {
    const allDeviceList: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices().catch((err) => {
      console.error(err);
      return [];
    });
    const deviceList: MediaDeviceInfo[] = allDeviceList.filter(
      (device: { kind: string; deviceId: string }) => device.kind === 'videoinput' && device.deviceId,
    );
    setDeviceList({
      currentVideoInDevices: deviceList,
    });
  }, []);

  return { videoDeviceList, findMediaVideoDevices };
}
