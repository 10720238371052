// TODO: テストを追加する

import { useCallback, useState } from 'react';

export const useUpdateVideoDevice = () => {
  const [videoDeviceId, setVideoDeviceId] = useState<string | null | undefined>(undefined);

  const updateVideoDeviceId = useCallback((deviceId: string | null) => {
    setVideoDeviceId(deviceId);
  }, []);
  return { videoDeviceId, updateVideoDeviceId };
};
