import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { AzureAuthForTranslate } from '../../../types/Azure/azureAuth.types';

export type AzureAuth = {
  token: string;
  expireAt: string;
  tokenForAutoResponse: string;
  expireAtForAutoResponse: string;
};

const initialState: AzureAuth = {
  token: '',
  expireAt: '',
  tokenForAutoResponse: '',
  expireAtForAutoResponse: '',
};

export const azureAuthSlice = createSlice({
  name: 'azureAuth',
  initialState,
  reducers: {
    updateAzureAuth: (state, action: PayloadAction<AzureAuthForTranslate>) => {
      state.token = action.payload.token;
      state.expireAt = action.payload.expireAt;
    },
    updateAzureAuthForAutoResponse: (
      state,
      action: PayloadAction<Pick<AzureAuth, 'tokenForAutoResponse' | 'expireAtForAutoResponse'>>,
    ) => {
      state.tokenForAutoResponse = action.payload.tokenForAutoResponse;
      state.expireAtForAutoResponse = action.payload.expireAtForAutoResponse;
    },
  },
});

export const { updateAzureAuth, updateAzureAuthForAutoResponse } = azureAuthSlice.actions;

export default azureAuthSlice.reducer;
