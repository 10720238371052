import { createSlice } from '@reduxjs/toolkit';

export type DialogState = {
  isOpenWidgetChat: boolean;
};

const initialState: DialogState = {
  isOpenWidgetChat: false,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    resetWidget: () => initialState,
    openWidgetChat: (state) => {
      state.isOpenWidgetChat = true;
    },
    closeWidgetChat: (state) => {
      state.isOpenWidgetChat = false;
    },
  },
});

export const { openWidgetChat, closeWidgetChat } = dialogSlice.actions;
export default dialogSlice.reducer;
