/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  URL: any;
};

export type AvatarUtteranceParams = {
  __typename?: 'AvatarUtteranceParams';
  /**  表示する発話内容  */
  displayText: Scalars['String'];
  /**  表情名  */
  facialExpression?: Maybe<Scalars['String']>;
  /**  ジェスチャ名  */
  gesture?: Maybe<Scalars['String']>;
};

export type BranchSelectionInput = {
  /**  カスタマーの回答  */
  customerHistory: CustomerHistory;
  /**  シナリオデータの Node ID  */
  nodeId: Scalars['String'];
  /**  シナリオ UUID  */
  scenarioUuid: Scalars['ID'];
  selection: Scalars['Int'];
};

export type BranchSelectionResult = {
  __typename?: 'BranchSelectionResult';
  /**  オンラインのオペレータの人数  */
  activeOperators: Scalars['Int'];
  /**  通話までのターン数  */
  remainingTurnNum: Scalars['Float'];
  /**  シナリオのデータ  */
  scenarioData: Array<ScenarioData>;
};

export type ChatGptModeParams = {
  __typename?: 'ChatGPTModeParams';
  promptId: Scalars['String'];
  requestLimitCount?: Maybe<Scalars['Int']>;
};

export type ChatOrTalkParams = {
  __typename?: 'ChatOrTalkParams';
  devicePermissionRequestMessage: Scalars['String'];
  postScenarioMessage: Scalars['String'];
};

export enum CustomRule {
  /**  空文字, 空配列禁止  */
  NotEmpty = 'NotEmpty',
  /**  パストラバーサル禁止  */
  NotPathTraversal = 'NotPathTraversal'
}

/** カスタマー */
export type Customer = {
  __typename?: 'Customer';
  /**  customerドキュメントのlistenPath  */
  customerDocumentListenPath: Scalars['String'];
  /**  カスタマーのfirebase uuid  */
  firebaseUuid: Scalars['String'];
  /**  customerドキュメントのlistenPath  */
  firestoreCustomerListenPath: Scalars['String'];
  /**  roomドキュメントのlistenPath  */
  firestoreRoomListenPath: Scalars['String'];
  /**  id:uuidに切り替えたら削除する  */
  id: Scalars['Int'];
  /**  プロジェクト  */
  project: Project;
  /**  プロジェクトid:uuidに切り替えたら削除する  */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  realtime database の customer のlistenPath  */
  realtimeDBListenPath: Scalars['String'];
  /**  roomドキュメントのlistenPath  */
  roomDocumentListenPath: Scalars['String'];
};

export type CustomerHistory = {
  instanceType: ScenarioInstanceType;
  sessionId: Scalars['String'];
  texts: Array<Scalars['String']>;
};

export type EndOfScenarioParams = {
  __typename?: 'EndOfScenarioParams';
  endType: EndType;
  postScenarioMessage: Scalars['String'];
};

export enum EndType {
  Chat = 'CHAT',
  Solved = 'SOLVED',
  Talk = 'TALK'
}

export type GptMessage = {
  content: Scalars['String'];
  role: Scalars['String'];
};

export type InputResultInput = {
  /**  カスタマーの回答  */
  customerHistory: CustomerHistory;
  /**  シナリオデータの Node ID  */
  nodeId: Scalars['String'];
  result: Scalars['String'];
  /**  シナリオ UUID  */
  scenarioUuid: Scalars['ID'];
};

export type InputResultResult = {
  __typename?: 'InputResultResult';
  /**  オンラインのオペレータの人数  */
  activeOperators: Scalars['Int'];
  /**  通話までのターン数  */
  remainingTurnNum: Scalars['Float'];
  /**  シナリオのデータ  */
  scenarioData: Array<ScenarioData>;
};

export type Motion = {
  __typename?: 'Motion';
  /** gestureのtype */
  type: Scalars['String'];
  /** gestureに紐づく動画のURL */
  url: Scalars['URL'];
};

/** 動画 */
export type Movie = {
  __typename?: 'Movie';
  /**  自動ミュート  */
  autoMicMute?: Maybe<Scalars['Boolean']>;
  /**  カテゴリー  */
  category: MovieCategory;
  /**  動画の長さ（秒）  */
  duration: Scalars['Int'];
  /**  id:uuidに切り替えたら削除する  */
  id: Scalars['Int'];
  /**  ラベル  */
  label: Scalars['String'];
  /**  プロジェクトid:uuidに切り替えたら削除する  */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  動画のURL  */
  url: Scalars['String'];
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** 動画カテゴリー */
export enum MovieCategory {
  /**  ADT背景動画  */
  AdtBackground = 'ADT_BACKGROUND',
  /**  マイクミュートあり動画  */
  Attention = 'ATTENTION',
  /**  会話終了時に再生される動画  */
  CallEnd = 'CALL_END',
  /**  会話開始時に再生される動画  */
  CallStart = 'CALL_START',
  /**  入室時のエントランスで再生される動画  */
  Entrance = 'ENTRANCE',
  /**  顔検知したときに再生される動画  */
  FaceDetection = 'FACE_DETECTION',
  /**  マイクミュートなし動画  */
  Interaction = 'INTERACTION',
  /**  ボタン検知したときに再生される動画  */
  Invocation = 'INVOCATION',
  /**  待機動画  */
  Offline = 'OFFLINE'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** 分岐通知 */
  branchSelection: BranchSelectionResult;
  /** 分岐通知 */
  inputResult: InputResultResult;
  /** CoTルーム入室 */
  signIn: Customer;
  /** シナリオ開始 */
  startScenario: StartScenarioResult;
  /** ChatGPTモード入力通知 */
  talkToChatGPT: TalkToChatGptResult;
};


export type MutationBranchSelectionArgs = {
  input: BranchSelectionInput;
};


export type MutationInputResultArgs = {
  input: InputResultInput;
};


export type MutationSignInArgs = {
  scenarioUuid: Scalars['ID'];
};


export type MutationStartScenarioArgs = {
  input: StartScenarioInput;
};


export type MutationTalkToChatGptArgs = {
  input: TalkToChatGptInput;
};

/** プロジェクト */
export type Project = {
  __typename?: 'Project';
  /**  id:uuidに切り替えたら削除する  */
  id: Scalars['Int'];
  /**  動画  */
  movies: Array<Movie>;
  /**  プロジェクト名  */
  name: Scalars['String'];
  /**  ルーム  */
  room: Room;
  /**  プロジェクト設定  */
  setting: ProjectSetting;
  /**  uuid  */
  uuid: Scalars['ID'];
};

/** プロジェクト設定 */
export type ProjectSetting = {
  __typename?: 'ProjectSetting';
  /**  GoogleアナリティクスID  */
  gaTagID?: Maybe<Scalars['String']>;
  /**  id:uuidに切り替えたら削除する  */
  id: Scalars['Int'];
  /**  プロジェクトid:uuidに切り替えたら削除する  */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  カスタマー(CoTルーム)のSession Replayの使用有無  */
  useCoTRoomSessionReplay: Scalars['Boolean'];
  /**  CRM連携使用有無  */
  useCrm: Scalars['Boolean'];
  /**  uuid  */
  uuid: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** 埋め込みページのドメイン取得 */
  embedDomain: Scalars['String'];
};


export type QueryEmbedDomainArgs = {
  scenarioUuid: Scalars['ID'];
};

/** ルーム */
export type Room = {
  __typename?: 'Room';
  /**  id:uuidに切り替えたら削除する  */
  id: Scalars['Int'];
  /**  ルームキー  */
  key: Scalars['String'];
  /**  listenPath  */
  listenPath: Scalars['String'];
  /**  ルーム名  */
  name: Scalars['String'];
  /**  ルームの待機動画のID  */
  offlineMovieId: Scalars['Int'];
  /**  ルームの待機動画のuuid  */
  offlineMovieUUID: Scalars['ID'];
  /**  プロジェクトid:uuidに切り替えたら削除する  */
  projectId: Scalars['Int'];
  /**  プロジェクトuuid  */
  projectUuid: Scalars['ID'];
  /**  ルームタイプ  */
  type: RoomType;
  /**  UUID  */
  uuid: Scalars['ID'];
};

/** ルームタイプ */
export enum RoomType {
  /**  CoTルーム  */
  Cot = 'COT',
  /**  常設ルーム  */
  Permanent = 'PERMANENT',
  /**  予約ルーム  */
  Scheduled = 'SCHEDULED',
  /**  WEBルーム  */
  Webbased = 'WEBBASED'
}

export type ScenarioData = {
  __typename?: 'ScenarioData';
  /**  Instance の種類  */
  instanceType: ScenarioInstanceType;
  /**  Node の ID  */
  nodeId: Scalars['String'];
  /**  パラメータマップ  */
  params?: Maybe<ScenarioParams>;
};

export enum ScenarioInstanceType {
  /**  アバターの発話内容  */
  AvatarUtterance = 'AVATAR_UTTERANCE',
  /**  ユーザーの自由入力でChatGPTからの回答を得る(ループ)  */
  ChatgptMode = 'CHATGPT_MODE',
  ChatOrTalk = 'CHAT_OR_TALK',
  ChatOrTalkChoice = 'CHAT_OR_TALK_CHOICE',
  DevicePermissionRequest = 'DEVICE_PERMISSION_REQUEST',
  /**  シナリオ終了  */
  EndOfScenario = 'END_OF_SCENARIO',
  TalkConfirm = 'TALK_CONFIRM',
  TimerDisplay = 'TIMER_DISPLAY',
  /**  選択肢表示  */
  UserChoices = 'USER_CHOICES',
  /**  自由入力欄表示  */
  UserInput = 'USER_INPUT'
}

export type ScenarioParams = AvatarUtteranceParams | ChatGptModeParams | ChatOrTalkParams | EndOfScenarioParams | TimerDisplayParams | UserChoicesParams | UserInputParams;

export type StartScenarioInput = {
  /**  シナリオ UUID  */
  scenarioUuid: Scalars['ID'];
};

/** startScenario の result */
export type StartScenarioResult = {
  __typename?: 'StartScenarioResult';
  /**  オンラインのオペレータの人数  */
  activeOperators: Scalars['Int'];
  /**  motionのリスト  */
  motions: Array<Motion>;
  /**  通話までのターン数  */
  remainingTurnNum: Scalars['Float'];
  /**  シナリオのデータ  */
  scenarioData: Array<ScenarioData>;
  /**  ルームのセッションID  */
  sessionID: Scalars['String'];
};

export type TalkToChatGptInput = {
  /**  カスタマーの回答  */
  customerHistory: CustomerHistory;
  isHistoryLimitExceeded: Scalars['Boolean'];
  messages: Array<GptMessage>;
  /**  シナリオデータの Node ID  */
  nodeId: Scalars['String'];
  /**  prompt の ID  */
  promptId: Scalars['String'];
  /**  カスタマーの回答  */
  result: Scalars['String'];
  scenarioUuid: Scalars['ID'];
};

export type TalkToChatGptResult = {
  __typename?: 'TalkToChatGPTResult';
  /**  オンラインのオペレータの人数  */
  activeOperators: Scalars['Int'];
  /**  ChatGPT の使用量  */
  chatGPTUsageAmount: Scalars['Float'];
  /**  Node の ID  */
  nodeId: Scalars['String'];
  /**  prompt の ID  */
  promptId: Scalars['String'];
  /**  シナリオのデータ  */
  scenarioData: Array<ScenarioData>;
};

export type TimerDisplayParams = {
  __typename?: 'TimerDisplayParams';
  operatorReadyMessage: Scalars['String'];
  soonOperatorMessage: Scalars['String'];
  waitingMessage: Scalars['String'];
};

export type UserChoicesParams = {
  __typename?: 'UserChoicesParams';
  choices: Array<Scalars['String']>;
  /**  選択結果を保存する場合のキー名  */
  saveName?: Maybe<Scalars['String']>;
};

export type UserInputParams = {
  __typename?: 'UserInputParams';
  inputType?: Maybe<Scalars['String']>;
  /**  選択結果を保存する場合のキー名  */
  saveName?: Maybe<Scalars['String']>;
};
