import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProjectSettingState = {
  useFaceDetection: boolean;
  useButtonDetection: boolean;
  usePermanentRoomDisplayMicWarning: boolean;
  gaTagID?: string | null;
};

const initialState: ProjectSettingState = {
  useFaceDetection: false,
  useButtonDetection: false,
  usePermanentRoomDisplayMicWarning: false,
  gaTagID: null,
};

export const projectSettingSlice = createSlice({
  name: 'projectSetting',
  initialState,
  reducers: {
    resetProjectSetting: () => initialState,
    updateProjectSetting: (
      state,
      action: PayloadAction<{
        useFaceDetection: boolean;
        useButtonDetection: boolean;
        usePermanentRoomDisplayMicWarning: boolean;
      }>,
    ) => {
      state.useFaceDetection = action.payload.useFaceDetection;
      state.useButtonDetection = action.payload.useButtonDetection;
      state.usePermanentRoomDisplayMicWarning = action.payload.usePermanentRoomDisplayMicWarning;
    },
    updateGaTagID: (state, action: PayloadAction<Pick<ProjectSettingState, 'gaTagID'>>) => {
      state.gaTagID = action.payload.gaTagID;
    },
  },
});

export const { resetProjectSetting, updateProjectSetting, updateGaTagID } = projectSettingSlice.actions;

export default projectSettingSlice.reducer;
