import { useMemo } from 'react';
import type { UseMediaDeviceProps } from './useMediaDevice.types';
import { useUpdateAudioDevice } from './useUpdateAudioDevice.hook';
import { useUpdateVideoDevice } from './useUpdateVideoDevice.hook';
import { useGetUserVideoDevices } from './useGetUserVideoDevices';
import { useGetUserAudioDevices } from './useGetUserAudioDevices';
import { useFindMediaVideoDevices } from './useFindMediaVideoDevices';
import { useOperatorVideoDevice } from './useOperatorVideoDevice';

export const useMediaDevice = ({ handleError }: UseMediaDeviceProps) => {
  const { userVideoDevices, getUserVideoDevices } = useGetUserVideoDevices({ handleError });
  const { isRequestAudioDeviceInProgress, userAudioDevices, getUserAudioDevices } = useGetUserAudioDevices({
    handleError,
  });
  const { audioDeviceId, updateAudioDeviceId } = useUpdateAudioDevice();
  const { videoDeviceId, updateVideoDeviceId } = useUpdateVideoDevice();
  const { operatorVideoDeviceId, updateOperatorVideoDeviceId } = useOperatorVideoDevice();
  const { videoDeviceList, findMediaVideoDevices } = useFindMediaVideoDevices();

  const value = useMemo(
    () => ({
      selectedAudioDeviceId: audioDeviceId,
      selectedVideoDeviceId: videoDeviceId,
      operatorVideoDeviceId,
      userVideoDevices,
      isRequestAudioDeviceInProgress,
      userAudioDevices,
      videoDeviceList,
      updateAudioDeviceId,
      updateVideoDeviceId,
      updateOperatorVideoDeviceId,
      getUserVideoDevices,
      getUserAudioDevices,
      findMediaVideoDevices,
    }),
    [
      audioDeviceId,
      videoDeviceId,
      operatorVideoDeviceId,
      userVideoDevices,
      isRequestAudioDeviceInProgress,
      userAudioDevices,
      videoDeviceList,
      updateAudioDeviceId,
      updateVideoDeviceId,
      updateOperatorVideoDeviceId,
      getUserVideoDevices,
      getUserAudioDevices,
      findMediaVideoDevices,
    ],
  );

  return { value };
};
