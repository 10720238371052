import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useUpdateCustomerCameraSettingMutation } from '../../../../graphql/mutation.generated';
import { CustomerCameraDeviceInput } from '../../../../types';
import { UseSendVideoDeviceResolutionArgs } from './useSendVideoDeviceResolution.types';

export const useSendVideoDeviceResolution = ({ videoTrack, videoDeviceList }: UseSendVideoDeviceResolutionArgs) => {
  const [updateCustomerCameraSetting] = useUpdateCustomerCameraSettingMutation();

  useEffect(() => {
    // NOTE: カメラデバイスやビデオトラックの取得前は何もしない
    if (videoDeviceList === null || videoTrack === undefined) return;

    // videoTrackがnoneの場合はsettingsをundefinedとする
    const settings = videoTrack !== 'none' ? videoTrack.getSettings() : undefined;

    // CustomerCameraDeviceInput[]に変換する
    const cameraDeviceInput: CustomerCameraDeviceInput[] = videoDeviceList.map((device) => ({
      deviceId: device.deviceId,
      label: device.label,
    }));

    // NOTE: オペレータのみが必要とするデータのため、
    // ここがもし失敗した場合もカスタマー側ではエラーをユーザにフィードバックしない（値が送信されていない場合はオペレータ側にフィードバックする）
    updateCustomerCameraSetting({
      variables: {
        originWidth: settings?.width || 0,
        originHeight: settings?.height || 0,
        mainCameraDeviceId: settings?.deviceId || undefined,
        devices: cameraDeviceInput,
      },
    }).catch((e) => {
      // カスタマー側ではエラーをユーザにフィードバックしないのでエラーをここでキャッチする
      captureException(e);
    });
  }, [updateCustomerCameraSetting, videoDeviceList, videoTrack]);
};
