import { useEffect, useState } from 'react';
import { getUserAudioTrack } from '../../LocalMediaStreamProvider.utils';
import { UseLocalAudioTrackProps } from './useLocalAudioTrack.types';

export const useLocalAudioTrack = ({ selectedAudioDeviceId, handleError }: UseLocalAudioTrackProps) => {
  const [audioTrack, setAudioTrack] = useState<MediaStreamTrack | null | undefined>(undefined);

  useEffect(() => {
    if (selectedAudioDeviceId === undefined) return;
    if (selectedAudioDeviceId === null) {
      setAudioTrack(null);
    } else {
      (async () => {
        const track = await getUserAudioTrack(selectedAudioDeviceId);
        setAudioTrack(track);
      })().catch(() => {
        setAudioTrack(null);
        if (handleError) {
          handleError('マイクに接続できません。別のアプリケーションで使用されている可能性があります。');
        }
      });
    }
  }, [selectedAudioDeviceId, handleError]);

  return { audioTrack };
};
