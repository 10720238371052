import { useEffect, useMemo } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useSelector } from 'react-redux';
import { retryLink } from '@avita-co-jp/react-providers';
import { ApolloAuthProviderProps } from './ApolloAuthProvider.types';
import { RootState } from '../../store';
import { authLink, httpLink } from './ApolloLinks';
import { useEnsureIdentity } from '../../hooks/useEnsureIdentity';

// FIXME: テストのcoverageを上げる
export const ApolloAuthProvider = ({ children }: ApolloAuthProviderProps) => {
  const { ensureIdentity } = useEnsureIdentity();
  // TODO: 削除する。authFirebaseの依存を外したい
  const authFirebase = useSelector((state: RootState) => state.authFirebase.identity);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([retryLink, authLink, httpLink]),
      }),
    [],
  );

  // TODO: 削除する（慎重に行うためフェーズを分ける）
  useEffect(() => {
    if (!authFirebase) return;
    const link = setContext(async (_, { headers }) =>
      ensureIdentity()
        .then((identity) => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          headers: {
            ...headers,
            authorization: `Bearer ${identity.firebaseToken}`,
          },
        }))
        .catch((e) => {
          console.error(e);
        }),
    );
    apolloClient.setLink(ApolloLink.from([retryLink, link, httpLink]));
  }, [apolloClient, authFirebase, ensureIdentity]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
