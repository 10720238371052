import { useCallback } from 'react';
import { IdentityState } from '../../firebase/auth.types';
import { useRefreshIdentityFirebase } from '../useRefreshIdentityFirebase';

// tokenの更新処理を行うhook
export function useEnsureIdentity() {
  const { refreshIdentityFirebase } = useRefreshIdentityFirebase();

  const ensureIdentity = useCallback(async (): Promise<IdentityState> => {
    // eslint-disable-next-line no-console
    console.warn('firebaseToken is expired. Trying to refresh identity...');
    const refreshedIdentity = await refreshIdentityFirebase();

    // eslint-disable-next-line no-console
    console.warn('To refresh identity is success.');
    return refreshedIdentity;
  }, [refreshIdentityFirebase]);

  // FIXME: 更新、利用されるのはFirebaseTokenだけなのでidentityでなく、tokenだけで良さそう
  return { ensureIdentity };
}
