import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ConvertedDateRoomDocument } from '../../../types/firestore';

export type FirestoreRoomState = Partial<ConvertedDateRoomDocument>;

const initialState: FirestoreRoomState = {};

export const firestoreRoomSlice = createSlice({
  name: 'firestoreRoom',
  initialState,
  reducers: {
    resetFirestoreRoomState: () => initialState,
    updateFirestoreRoomState: (state, action: PayloadAction<FirestoreRoomState | undefined>) => ({
      ...state,
      ...action.payload,
    }),
    disableReloadRequired: (state) => {
      state.reloadRequired = false;
    },
  },
});

export const { resetFirestoreRoomState, updateFirestoreRoomState, disableReloadRequired } = firestoreRoomSlice.actions;

export default firestoreRoomSlice.reducer;
