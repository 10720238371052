import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// TODO: issue1464（https://github.com/avita-co-jp/avatar-diving-dashboard/issues/1464） リリース後その他ルームもisEndCallをreduxで管理する
const initialState = false;

export const isEndCallSlice = createSlice({
  name: 'isEndCall',
  initialState,
  reducers: {
    setIsEndCall: (_, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { setIsEndCall } = isEndCallSlice.actions;

export default isEndCallSlice.reducer;
