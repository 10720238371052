import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

export const isClickPrimaryButtonFixedSlice = createSlice({
  name: 'isClickPrimaryButtonFixed',
  initialState,
  reducers: {
    setIsClickPrimaryButtonFixed: (_, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { setIsClickPrimaryButtonFixed } = isClickPrimaryButtonFixedSlice.actions;

export default isClickPrimaryButtonFixedSlice.reducer;
