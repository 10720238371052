import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: boolean = false;

export const kioskModeSlice = createSlice({
  name: 'kioskMode',
  initialState,
  reducers: {
    updateKioskMode: (state, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { updateKioskMode } = kioskModeSlice.actions;

export default kioskModeSlice.reducer;
