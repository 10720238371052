import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RoomSessionState = {
  isClosed: boolean;
  sessionId: string;
  createdAt: string;
  updatedAt: string;
  closeAt: string;
  lastChatAt?: string;
  lastSelectiveTalkAt?: string;
  lastCotChatAt?: string;
  customerReadAt?: string;
  operatorReadAt?: string;
};

const initialState: Partial<RoomSessionState> = {};

export const firestoreRoomSessionSlice = createSlice({
  name: 'firestoreRoomSessionSlice',
  initialState,
  reducers: {
    resetFirestoreRoomSessionState: () => initialState,
    updateFirestoreRoomSessionState: (state, action: PayloadAction<Partial<RoomSessionState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { resetFirestoreRoomSessionState, updateFirestoreRoomSessionState } = firestoreRoomSessionSlice.actions;

export default firestoreRoomSessionSlice.reducer;
