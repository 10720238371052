import { useCallback, useState } from 'react';

export const useOperatorVideoDevice = () => {
  const [operatorVideoDeviceId, setOperatorVideoDeviceId] = useState<string | null | undefined>(undefined);

  const updateOperatorVideoDeviceId = useCallback((deviceId: string | null) => {
    setOperatorVideoDeviceId(deviceId);
  }, []);
  return { operatorVideoDeviceId, updateOperatorVideoDeviceId };
};
