import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

export const isConnectedErrorSlice = createSlice({
  name: 'isConnectedError',
  initialState,
  reducers: {
    setIsConnectedError: (_, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { setIsConnectedError } = isConnectedErrorSlice.actions;

export default isConnectedErrorSlice.reducer;
