import { useCallback, useMemo, useState } from 'react';
import type { UseGetUserVideoDevicesProps } from './useGetUserVideoDevices.types';
import { UserVideoDevices } from '../../../../types/MediaDevice';

export const useGetUserVideoDevices = ({ handleError }: UseGetUserVideoDevicesProps) => {
  const [userVideoDevices, setUserVideoDevices] = useState<UserVideoDevices>({
    isVideoAllow: null,
  });
  const constraints: MediaStreamConstraints = useMemo(() => ({ video: true }), []);

  const getUserVideoDevices = useCallback(async () => {
    const isAllow = await navigator.mediaDevices
      ?.getUserMedia(constraints)
      .then((stream) => {
        // NOTE: ストリームリストのキャプチャ状態を開放する
        stream.getTracks().forEach((track) => track.stop());
        return true;
      })
      .catch((e) => {
        if (e instanceof DOMException && e.name === 'NotReadableError') {
          // NOTE: NotReadableErrorの時はデバイスへのアクセスはできているのでtrueにする
          return true;
        }
        if (e instanceof DOMException && e.name === 'NotAllowedError') {
          if (handleError) {
            handleError('カメラにアクセスできません。ブラウザでカメラの使用を許可してください。');
          }
        }
        // NOTE: デバイスにアクセスできないのでfalseにする(NotAllowedError,NotFoundError,OverconstrainedError,SecurityError,TypeError)
        // 詳細：(https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia#%E4%BE%8B%E5%A4%96)
        return false;
      });
    setUserVideoDevices({ isVideoAllow: isAllow });
  }, [constraints, handleError]);

  return { userVideoDevices, getUserVideoDevices };
};
