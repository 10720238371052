import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RoomMovieOrder } from '@avita-co-jp/react-providers';

// TODO: UUID対応 id の削除 (movieId)
const initialState: RoomMovieOrder = {
  id: null,
  uuid: null,
  duration: 0,
  event: null,
  loop: false,
};

export const movieOrderSlice = createSlice({
  name: 'movieOrder',
  initialState,
  reducers: {
    updateMovieOrder: (state, action: PayloadAction<RoomMovieOrder>) => ({
      ...state,
      ...action.payload,
    }),
    resetMovieOrder: () => initialState,
  },
});

export const { updateMovieOrder, resetMovieOrder } = movieOrderSlice.actions;

export default movieOrderSlice.reducer;
