import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../store';

// NOTE: MainStream用のVideoTrackを差し替えるためのhook
export const useLocalMaskedVideoTrack = () => {
  const selectedRoom = useSelector((state: RootState) => state.customer.selectedRoom);
  const [localMaskedVideoTrack, setLocalMaskedVideoTrack] = useState<MediaStreamTrack | null>(null);
  const [isFaceMaskEnabled, setIsFaceMaskEnabled] = useState(true);

  const updateFaceMaskEnabled = useCallback(
    (status: boolean) => {
      if (!selectedRoom?.useFaceMask) return;
      setIsFaceMaskEnabled(status);
    },
    [selectedRoom],
  );

  const updateLocalMaskedVideoTrack = useCallback(
    (videoTrack: MediaStreamTrack | null) => {
      if (!selectedRoom?.useFaceMask) return;
      setLocalMaskedVideoTrack(videoTrack);
    },
    [selectedRoom],
  );

  return {
    localMaskedVideoTrack,
    updateLocalMaskedVideoTrack,
    isFaceMaskEnabled,
    updateFaceMaskEnabled,
  };
};
