// カスタマーの入室状態
// 0: 待機中
// 1: 入室済み
// 2: 退室済み
// 3: 離脱済み
// https://github.com/avita-co-jp/add-backend/blob/f1bdcceff2423c609d215969903a102e59fc945c/go/polaris/internal/taurus/v1/entity/room.go#L306-L311
export type CustomerRoomStatus = 0 | 1 | 2 | 3;

export type CustomerDocument = {
  id: string;
  matchingStatus: CustomerMatchingStatus;
  onlineStatus: CustomerOnlineStatus;
  roomStatus: CustomerRoomStatus;
  numberOfWaitingCustomerBeforeMe: number;
  isNumberOfWaitingCustomerAboveThreshold: boolean;
  roomKey: string;
  roomName: string;
  isAssigned: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export enum CustomerMatchingStatus {
  Waiting = 0,
  NotAvailable,
  Crowded,
  Working,
  Finished,
}

export enum CustomerOnlineStatus {
  Offline = 0,
  Online,
}

export type CustomerRealtimeDatabaseData = {
  onlineStatus: CustomerOnlineStatus;
  projectId: string;
  roomKey: string;
  roomName: string;
  lastChanged: number;
};
