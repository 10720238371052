/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type CustomerCameraSettingFragmentFragment = { __typename?: 'CustomerCameraSetting', mainCameraDeviceId?: string | null, originHeight: number, originWidth: number };

export type MovieFragmentFragment = { __typename?: 'Movie', id: number, uuid: string, label?: string | null, url?: string | null, category?: Types.MovieCategory | null, autoMicMute?: boolean | null, duration: number, createdAt: any, updatedAt: any };

export type ImageFragmentFragment = { __typename?: 'Image', uuid: string, label: string, url: string, category: Types.ImageCategory };

export type RoomFragmentFragment = { __typename?: 'Room', id: number, uuid: string, key: string, name: string, faceDetectionAlertDuration?: number | null, faceDetectionBoxX?: number | null, faceDetectionBoxY?: number | null, faceDetectionBoxWidth?: number | null, faceDetectionBoxHeight?: number | null, faceDetectionFps?: number | null, useFaceMask?: boolean | null, documentListenPath: string, projectId: number, projectUuid: string, type: Types.RoomType, createdAt: any, updatedAt: any, rtcSDK: Types.RoomRtcSdk, assignedScenario?: { __typename?: 'Scenario', uuid: string, name: string, motions: Array<{ __typename?: 'ScenarioMotion', type: Types.ScenarioMotionType, url: string }> } | null, offlineMovie?: { __typename?: 'Movie', id: number, uuid: string, label?: string | null, url?: string | null, category?: Types.MovieCategory | null, autoMicMute?: boolean | null, duration: number, createdAt: any, updatedAt: any } | null, customerStreamQuality: { __typename?: 'StreamQuality', fpsMode: Types.RoomFpsMode, frameRate?: number | null, bitRate: number }, permanentRoomSetting?: { __typename?: 'PermanentRoomSetting', useCustomerRendering: boolean, customerRenderingMovieCategory: Types.CustomerRenderingMovieCategory } | null };

export type AdtBackgroundFragmentFragment = { __typename?: 'ADTBackground', label: string, url: string };

export type ProjectFragmentFragment = { __typename?: 'Project', id: number, uuid: string, name: string, groupId: number, groupUuid: string, movies: Array<{ __typename?: 'Movie', id: number, uuid: string, label?: string | null, url?: string | null, category?: Types.MovieCategory | null, autoMicMute?: boolean | null, duration: number, createdAt: any, updatedAt: any }>, rooms: Array<{ __typename?: 'Room', id: number, uuid: string, key: string, name: string, faceDetectionAlertDuration?: number | null, faceDetectionBoxX?: number | null, faceDetectionBoxY?: number | null, faceDetectionBoxWidth?: number | null, faceDetectionBoxHeight?: number | null, faceDetectionFps?: number | null, useFaceMask?: boolean | null, documentListenPath: string, projectId: number, projectUuid: string, type: Types.RoomType, createdAt: any, updatedAt: any, rtcSDK: Types.RoomRtcSdk, assignedScenario?: { __typename?: 'Scenario', uuid: string, name: string, motions: Array<{ __typename?: 'ScenarioMotion', type: Types.ScenarioMotionType, url: string }> } | null, offlineMovie?: { __typename?: 'Movie', id: number, uuid: string, label?: string | null, url?: string | null, category?: Types.MovieCategory | null, autoMicMute?: boolean | null, duration: number, createdAt: any, updatedAt: any } | null, customerStreamQuality: { __typename?: 'StreamQuality', fpsMode: Types.RoomFpsMode, frameRate?: number | null, bitRate: number }, permanentRoomSetting?: { __typename?: 'PermanentRoomSetting', useCustomerRendering: boolean, customerRenderingMovieCategory: Types.CustomerRenderingMovieCategory } | null }>, setting: { __typename?: 'ProjectSetting', useButtonDetection: boolean, useFaceDetection: boolean, gaTagID?: string | null, usePermanentRoomSessionReplay: boolean, useScheduledRoomSessionReplay: boolean, useWebBasedRoomSessionReplay: boolean, usePermanentRoomDisplayMicWarning: boolean }, adtBackgrounds: Array<{ __typename?: 'ADTBackground', label: string, url: string }> };

export type ScenarioFragmentFragment = { __typename?: 'Scenario', uuid: string, name: string, motions: Array<{ __typename?: 'ScenarioMotion', type: Types.ScenarioMotionType, url: string }> };

export type ScenarioMotionFragmentFragment = { __typename?: 'ScenarioMotion', type: Types.ScenarioMotionType, url: string };

export const CustomerCameraSettingFragmentFragmentDoc = gql`
    fragment customerCameraSettingFragment on CustomerCameraSetting {
  mainCameraDeviceId
  originHeight
  originWidth
}
    `;
export const ImageFragmentFragmentDoc = gql`
    fragment imageFragment on Image {
  uuid
  label
  url
  category
}
    `;
export const MovieFragmentFragmentDoc = gql`
    fragment movieFragment on Movie {
  id
  uuid
  label
  url
  category
  autoMicMute
  duration
  createdAt
  updatedAt
}
    `;
export const ScenarioMotionFragmentFragmentDoc = gql`
    fragment scenarioMotionFragment on ScenarioMotion {
  type
  url
}
    `;
export const ScenarioFragmentFragmentDoc = gql`
    fragment scenarioFragment on Scenario {
  uuid
  name
  motions {
    ...scenarioMotionFragment
  }
}
    ${ScenarioMotionFragmentFragmentDoc}`;
export const RoomFragmentFragmentDoc = gql`
    fragment roomFragment on Room {
  id
  uuid
  key
  name
  faceDetectionAlertDuration
  faceDetectionBoxX
  faceDetectionBoxY
  faceDetectionBoxWidth
  faceDetectionBoxHeight
  faceDetectionFps
  useFaceMask
  documentListenPath
  projectId
  projectUuid
  type
  createdAt
  updatedAt
  assignedScenario {
    ...scenarioFragment
  }
  offlineMovie {
    ...movieFragment
  }
  customerStreamQuality {
    fpsMode
    frameRate
    bitRate
  }
  permanentRoomSetting {
    useCustomerRendering
    customerRenderingMovieCategory
  }
  rtcSDK
}
    ${ScenarioFragmentFragmentDoc}
${MovieFragmentFragmentDoc}`;
export const AdtBackgroundFragmentFragmentDoc = gql`
    fragment adtBackgroundFragment on ADTBackground {
  label
  url
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment projectFragment on Project {
  id
  uuid
  name
  groupId
  groupUuid
  movies {
    ...movieFragment
  }
  rooms {
    ...roomFragment
  }
  setting {
    useButtonDetection
    useFaceDetection
    gaTagID
    usePermanentRoomSessionReplay
    useScheduledRoomSessionReplay
    useWebBasedRoomSessionReplay
    usePermanentRoomDisplayMicWarning
  }
  adtBackgrounds {
    ...adtBackgroundFragment
  }
}
    ${MovieFragmentFragmentDoc}
${RoomFragmentFragmentDoc}
${AdtBackgroundFragmentFragmentDoc}`;