import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { CustomToastOptions } from '../../utils/notify';
import { RootState } from '../../store';
import { notify } from '../../utils/notify';
import { RoomType } from '../../types';

// NOTE: ルーム種別でトースターを出し分ける（utils/notifyのwrapper）
// NOTE: Providerで使用するときは、独立性を考慮してpropsに関数を渡す
export const useNotify = () => {
  const roomType = useSelector((state: RootState) => state.customer.selectedRoom?.type);
  // NOTE: 常設ルームのみ有効
  const isEnabled = useMemo(() => roomType === RoomType.Permanent, [roomType]);

  const notifyError = useCallback(
    (message: string, toastOptions?: CustomToastOptions) => {
      if (isEnabled) {
        notify.error(message, toastOptions);
      } else {
        console.error(message);
      }
    },
    [isEnabled],
  );

  return {
    notifyError,
  };
};
