import { AuthErrorCodes } from 'firebase/auth';

type TErrorMessage = {
  [key: number]: string;
};

export const errorMessage: TErrorMessage = {
  400: '不明なエラーです',
  401: '認証に失敗しました',
  403: '接続が拒否されました',
  404: 'アクセス先がありません',
  407: 'プロキシサーバでエラーが発生しました',
  408: 'タイムアウトしました',
  415: '不正なアクセスです',
  500: 'サーバーでエラーが発生しました。',
  503: '現在サービスが利用できません',
};

export const authErrorCodes = [
  'auth/invalid-login-credentials', // 無効なログイン
  AuthErrorCodes.INVALID_AUTH, // 無効な認証
  AuthErrorCodes.INVALID_EMAIL, // 無効なemail
  AuthErrorCodes.INVALID_PASSWORD, // 無効なパスワード
  AuthErrorCodes.USER_DISABLED, // ユーザーが無効化されています
  AuthErrorCodes.USER_DELETED, // ユーザーが削除されている
  AuthErrorCodes.USER_MISMATCH, // ユーザーが一致しません
  AuthErrorCodes.INVALID_CUSTOM_TOKEN, // カスタムトークンが無効です
  AuthErrorCodes.INVALID_IDP_RESPONSE, // 資格情報が無効です
  AuthErrorCodes.INVALID_OOB_CODE, // アクションコードが無効です
  AuthErrorCodes.INVALID_CODE, // verificationコードが無効です
  AuthErrorCodes.INVALID_SESSION_INFO, // verificationIDが無効です
  AuthErrorCodes.INVALID_MFA_SESSION, // MFAセッションが無効です
  AuthErrorCodes.MFA_REQUIRED, // MFAが必要です
  AuthErrorCodes.USER_CANCELLED, // ユーザーがキャンセルしました
  AuthErrorCodes.USER_SIGNED_OUT, // ユーザーがサインアウトしました
  AuthErrorCodes.TOKEN_EXPIRED, // トークンが期限切れです
  AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER, // 多くのリクエストが送信されました
];
