import { useNotify } from '../../hooks/useNotify';
import { LocalMediaStreamProvider } from '../../providers/LocalMediaStreamProvider';
import { MediaDeviceProvider } from '../../providers/MediaDeviceProvider';

export const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  const { notifyError } = useNotify();
  return (
    <MediaDeviceProvider handleError={notifyError}>
      <LocalMediaStreamProvider handleError={notifyError}>{children}</LocalMediaStreamProvider>
    </MediaDeviceProvider>
  );
};
